import styled from '@emotion/styled'
import { Text } from 'components/Typography'

export const SHeaderText = styled(Text)`
  font-size: 1.25rem;
`

export const SPlusWrapper = styled.div`
  padding: 0 0.75rem;
  border-radius: 1.875rem;
  background-color: #ffa360;
`

export const SPlusText = styled(Text)`
  font-weight: 700;
  letter-spacing: 0.22em;
  font-size: 0.75rem;
  line-height: 200%;
  text-transform: uppercase;
`

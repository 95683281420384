import {
  LivePortraitFragment,
  SingingPortraitFragment,
  SingingPortraitSongFragment,
} from 'api/contentful/contentful.graphql.types'
import useSWR from 'swr'
import { isNonNullable } from 'utils/general'
import { contentful } from './api'

export async function getContentfulLivePortraits(): Promise<LivePortraitFragment[]> {
  return contentful
    .getDefaultLivePortraits()
    .then(
      (setup) =>
        setup.memorySetupCollection?.items?.[0]?.livePortraitsCollection?.items.filter(
          isNonNullable
        ) ?? []
    )
}

export async function getContentfulSingingPortraits(): Promise<SingingPortraitFragment[]> {
  return contentful
    .getDefaultSingingPortraits()
    .then(
      (setup) =>
        setup.memorySetupCollection?.items?.[0]?.singingPortraitsCollection?.items.filter(
          isNonNullable
        ) ?? []
    )
}

export async function getContentfulSingingPortraitSongs(): Promise<SingingPortraitSongFragment[]> {
  return contentful
    .getDefaultSingingPortraitSongs()
    .then(
      (setup) =>
        setup.memorySetupCollection?.items?.[0]?.singingPortraitSongsCollection?.items.filter(
          isNonNullable
        ) ?? []
    )
}

export function useContentfulLivePortraits() {
  return useSWR('contentful/livePortraits', () => getContentfulLivePortraits())
}

export function useContentfulSingingPortraits() {
  return useSWR('contentful/singingPortraits', () => getContentfulSingingPortraits())
}

export function useContentfulSingingPortraitSongs() {
  return useSWR('contentful/singingPortraitSongs', () => getContentfulSingingPortraitSongs())
}

export function useStoryPortraitCategories() {
  return useSWR('contentful/storyPortraitCategories', () => contentful.getStoryPortraitCategories())
}

export function useStoryPortraitCarousel() {
  return useSWR('contentful/storyPortaitCarousel', () => contentful.getStoryPortraitCarousel())
}

export function useStoryPortraitTips() {
  return useSWR('contentful/storyPortraitTips', () => contentful.getStoryPortraitTips())
}

export function getStoryPortraitAnimations() {
  return contentful
    .getStoryPortraitCarousel()
    .then((result) =>
      result.memorySetupCollection?.items[0]?.storyPortraitCarouselCollection?.items
        .filter(isNonNullable)
        .map((item): SingingPortraitFragment => {
          return {
            sys: {
              id: item.sys.id,
            },
            video: {
              url: item.url,
            },
          }
        })
    )
    .then((x) => x ?? [])
}

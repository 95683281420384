// 16 megapixels
export const MEGAPIXEL_LIMIT = 16 * 1e6

// 10 MB
export const MEMORY_SIZE_LIMIT = 10 * 1e6

const loadImage = (file: File): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new window.Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = URL.createObjectURL(file)
  })

export const isPictureTooBig = async (file: File): Promise<boolean> => {
  const loadedImage = await loadImage(file)
  const { width, height } = loadedImage
  return width * height >= MEGAPIXEL_LIMIT
}

import { apiAuthFetcher, apiFetcher } from 'api/goodtrust/api'
import FormData from 'isomorphic-form-data'
import { useRef } from 'react'
import useSWR, { SWRConfiguration } from 'swr'
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite'
import { encodeQuery, FetchResponse } from 'utils/fetcher'
import { getFormDataBodyWithFile } from 'utils/file'
import { encodePageQuery } from 'utils/pagination'
import { ApiType } from 'utils/types'

export function getUserMemory(memoryUuid: string) {
  return apiAuthFetcher<ApiType['MemoryResponse']>(`/api/v1/memory/${memoryUuid}`)
}

export function useUserMemory(
  memoryUuid?: string,
  config?: SWRConfiguration<FetchResponse<ApiType['MemoryResponse']>, Error>
) {
  return useSWR<FetchResponse<ApiType['MemoryResponse']>>(
    memoryUuid ? `/api/v1/memory/${memoryUuid}` : null,
    apiAuthFetcher,
    config
  )
}

export function useSharedUserMemory(
  memoryUuid: string,
  animationUuid?: string,
  config?: SWRConfiguration<FetchResponse<ApiType['MemoryResponse']>, Error>
) {
  return useSWR<FetchResponse<ApiType['MemoryResponse']>>(
    memoryUuid
      ? encodeQuery(`/api/v1/memory/public/${memoryUuid}`, { animation: animationUuid })
      : null,
    apiFetcher,
    config
  )
}

export function postUserMemory(file: File) {
  const body = getFormDataBodyWithFile({}, file)
  return apiAuthFetcher<ApiType['MemoryResponse']>(`/api/v1/memory`, {
    method: 'POST',
    body,
  })
}

export function updateUserMemory(memoryUuid: string, body: ApiType['MemoryUpdateRequest']) {
  return apiAuthFetcher<ApiType['MemoryResponse']>(`/api/v1/memory/${memoryUuid}`, {
    method: 'PUT',
    body,
  })
}

export function publicizeUserMemory(
  memoryUuid: string,
  source: ApiType['MemoryPublicizeSourceRequest']['source']
) {
  return apiAuthFetcher<ApiType['MemoryResponse']>(`/api/v1/memory/${memoryUuid}/publicize`, {
    method: 'PUT',
    body: { source },
  })
}

export function postMemoryPaymentPrepare(body: ApiType['AnimationBundleRequest']) {
  return apiAuthFetcher(`/api/v1/memory/prepare/payment`, {
    method: 'POST',
    body,
  })
}

export function useUserMemoryList(props?: {
  disabled?: boolean
  config?: SWRInfiniteConfiguration<
    FetchResponse<ApiType['PaginatedResponseMemoryResponse']>,
    Error
  >
  specification?: ApiType['MemorySpecification']
}) {
  return useSWRInfinite<FetchResponse<ApiType['PaginatedResponseMemoryResponse']>>(
    props?.disabled
      ? () => null
      : (_, prev) => encodePageQuery('/api/v1/memory', prev, props?.specification),
    apiAuthFetcher,
    { ...props?.config }
  )
}

export function useUserMemoryAnimationReserve(noCache?: boolean) {
  const random = useRef(Date.now())
  if (noCache) {
    return useSWR<FetchResponse<ApiType['AnimationReserveResponse']>>(
      [`/api/v1/memory/reserve`, random],
      apiAuthFetcher
    )
  } else {
    return useSWR<FetchResponse<ApiType['AnimationReserveResponse']>>(
      `/api/v1/memory/reserve`,
      apiAuthFetcher
    )
  }
}

export async function checkUserMemoryAnimationReserve() {
  return apiAuthFetcher<ApiType['AnimationReserveResponse']>(`/api/v1/memory/reserve`, {
    method: 'GET',
  })
}

export function getReserveTotal(reserve?: ApiType['AnimationReserveResponse']) {
  if (reserve) {
    let count = 0
    if (reserve.reserve != null) count += reserve.reserve
    if (reserve.free != null) count += reserve.free
    return count
  } else return undefined
}

function prepareAnimateBody(request: ApiType['MemoryAnimationRequest'], audioBlob?: Blob) {
  const body = new FormData()
  body.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }))
  if (audioBlob)
    body.append(
      'audioFile',
      new File([audioBlob], 'audio.wav', {
        type: 'audio/wav',
      })
    )

  return body
}

export function animateContentfulMemory(
  contentfulImageId: string,
  request: ApiType['MemoryAnimationRequest'],
  audioFile?: Blob
) {
  return apiAuthFetcher<ApiType['MemoryResponse']>(
    `/api/v1/memory/contentful/${contentfulImageId}/animate`,
    {
      method: 'POST',
      body: prepareAnimateBody(request, audioFile),
    }
  )
}

export function animateUserMemory(
  memoryUuid: string,
  request: ApiType['MemoryAnimationRequest'],
  audioFile?: Blob
) {
  return apiAuthFetcher<ApiType['MemoryResponse']>(`/api/v1/memory/${memoryUuid}/animate`, {
    method: 'POST',
    body: prepareAnimateBody(request, audioFile),
  })
}

export function deleteUserMemory(memoryUuid: string) {
  return apiAuthFetcher<void>(`/api/v1/memory/${memoryUuid}`, {
    method: 'DELETE',
  })
}

export function emailUserMemory(memoryUuid: string, body: ApiType['MemoryShareRequest']) {
  const fetcher = body.sender_email ? apiFetcher : apiAuthFetcher
  return fetcher(`/api/v1/memory/${memoryUuid}/share`, { method: 'POST', body })
}

export function putReferralMemoryUuid(memoryUuid: string) {
  return apiAuthFetcher<void>(`/api/v1/memory/${memoryUuid}/referral`, {
    method: 'PUT',
  })
}

export function useMemoryRedoEligible() {
  return useSWR<FetchResponse<ApiType['AnimationRedoEligibleResponse']>>(
    `/api/v1/memory/redo/eligible`,
    apiAuthFetcher
  )
}

export function postMemoryRedo(memoryUuid: string, body: ApiType['MemoryRedoAnimationRequest']) {
  return apiAuthFetcher<ApiType['MemoryResponse']>(`/api/v1/memory/${memoryUuid}/redo`, {
    method: 'POST',
    body,
  })
}

export function postSaveMemory(memoryUuid: string, animation?: string) {
  return apiAuthFetcher<void>(
    encodeQuery(`/api/v1/memory/save/${memoryUuid}`, {
      animation,
    }),
    {
      method: 'POST',
    }
  )
}

export function postPreviewTTS(body: ApiType['TextToSpeechCommand'], captchaToken: string) {
  return apiFetcher<ApiType['TextToSpeechResponse']>('/api/v1/text-to-speech/preview', {
    method: 'POST',
    body,
    headers: {
      'captcha-response': captchaToken,
    },
  })
}

import { css } from '@emotion/react'
import { useStoryPortraitCategories } from 'api/contentful/memory'
import { useUserMemoryList } from 'api/goodtrust/user/memory'
import { Button } from 'components/button/Button'
import { Icon, IconName } from 'components/icon'
import { useModal } from 'components/modal/Modal'
import { HStack, Stack } from 'components/Stack'
import { CategorySection } from 'components/storyPortraits/category/CategorySection'
import { useStoryPortraitLogic } from 'components/storyPortraits/logic'
import { Caption, Text } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import { colors } from 'utils/colors'
import { isNonNullable } from 'utils/general'
import { useOuterClick, useRunOnlyOnChange } from 'utils/hooks'
import { usePaginateToEnd } from 'utils/pagination'
import { BREAKPOINTS } from 'utils/styled'
import { SHeaderText, SPlusText, SPlusWrapper } from './StoryPortraitsModal.styled'

export const PlusLabel = () => {
  return (
    <SPlusWrapper>
      <SPlusText variant="inputLabel" color={colors.white}>
        Plus
      </SPlusText>
    </SPlusWrapper>
  )
}

const ModalButton = (props: {
  iconName: IconName
  text: string
  hidePlus?: boolean
  onClick?: () => void
}) => {
  return (
    <Button
      onClick={props.onClick}
      size="large"
      type="button"
      variant="round"
      leftIcon={<Icon name={props.iconName} color={colors.gray[100]} />}
      css={css`
        box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.15);
        border: 0;
      `}
    >
      <HStack>
        <Text color={colors.gray[100]} variant="bodyBold">
          {props.text}
        </Text>
        {!props.hidePlus && <PlusLabel />}
      </HStack>
    </Button>
  )
}

const StoryPortraitsModal = (props: { onClose: () => void }) => {
  const { t } = useTranslation('memory')
  const logic = useStoryPortraitLogic()
  const uploadButton = logic.useUploadButton(
    () => ref.current?.querySelector('input[data-dropzone-input]') ?? undefined
  )
  const selfieButton = logic.useSelfieButton()
  const ref = useOuterClick(props.onClose)
  const categories = useStoryPortraitCategories().data

  const memoryList = useUserMemoryList({
    specification: {
      fileSource: 'CUSTOM',
    },
  })
  usePaginateToEnd(memoryList)

  const userMemories = memoryList.data?.flatMap((x) => x.json?.items).filter(isNonNullable)

  useRunOnlyOnChange(logic.selected.picture?.url, () => {
    props.onClose()
  })

  return (
    <Stack space={0} ref={ref}>
      {selfieButton.modal}
      <input data-dropzone-input {...uploadButton.dropzone.getInputProps()} />
      <Stack
        space="1.5rem"
        css={css`
          padding-bottom: 1.5rem;
        `}
      >
        <HStack
          css={css`
            justify-content: space-between;
          `}
        >
          <HStack space="1rem">
            <Icon name="Will" color={colors.black} />
            <SHeaderText color={colors.black} variant="bodyBold">
              {t('memory.story.modal.choose_image_button')}
            </SHeaderText>
          </HStack>
          <HStack space="1rem">
            <Caption className="desktop-only">
              {t('memory.story.modal.step_no', {
                currentStep: 1,
                totalSteps: 3,
              })}
            </Caption>
            <Button type="button" variant="text" onClick={props.onClose}>
              <Icon name="Close" color={colors.gray[500]} size="0.75rem" />
            </Button>
          </HStack>
        </HStack>
        <Stack orientation={['vertical', 'vertical', 'horizontal', 'horizontal']} space="0.75rem">
          <ModalButton
            iconName="FileUploadFrame"
            text={t('memory.story.modal.upload_button')}
            onClick={uploadButton.onClick}
          />
          <ModalButton
            iconName="Camera"
            text={t('memory.story.modal.take_selfie_button')}
            onClick={selfieButton.onClick}
          />
        </Stack>
      </Stack>
      {categories && (
        <CategorySection
          userMemories={userMemories}
          categories={categories}
          onImageSelected={props.onClose}
          isModal={true}
        />
      )}
    </Stack>
  )
}

export const useStoryPortraitsModal = () => {
  const [modal, openModal, closeModal] = useModal(
    ({ close, isOpen }) => (isOpen ? <StoryPortraitsModal onClose={close} /> : null),
    {
      modalCss: css`
        max-width: 1440px;
        @media ${BREAKPOINTS.MD.min} {
          width: 90%;
          max-height: 80%;
          align-self: center;
        }
      `,
      modalCloseButtonCss: css`
        display: none;
      `,
    }
  )
  return [modal, openModal, closeModal] as const
}

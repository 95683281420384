import { IUploadedFile } from 'components/uploader/uploaderFile/UploaderFile'
import { getFileBlob } from 'api/goodtrust/file'
import { handleAndToastError } from 'components/Toast'
import { useEffect, useState } from 'react'
import { ApiType, GenericAsyncFunction } from './types'
import { safeWindow } from './general'
import { FileRejection } from 'react-dropzone'
import FormData from 'isomorphic-form-data'
import { TFunction } from 'react-i18next'
import { Translation } from 'next-i18next'
import { ApiError } from 'utils/error'

export const readFile = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onabort = () => reject({ type: 'abort' })
    reader.onerror = () => reject({ type: 'error' })
    reader.onload = () => resolve(reader.result as string)
    reader.readAsDataURL(file)
  })

export const openBlob = (blob: Blob | string, filename: string) => {
  const url = typeof blob === 'string' ? blob : window.URL.createObjectURL(blob)
  downloadUrl(url, filename)
  setTimeout(() => window.URL.revokeObjectURL(url), 1000)
}

export const downloadUrl = (url: string, filename: string, options?: { sameTab?: boolean }) => {
  const a = document.createElement('a')
  a.style.display = 'none'
  document.body.appendChild(a)

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = url
  if (!options?.sameTab) {
    a.target = '_blank'
  }
  a.download = filename
  a.click()

  document.body.removeChild(a)
}

type DownloadFileFunction = (file: IUploadedFile) => Promise<void>
type DownloadFileFunctionGenerator = () => DownloadFileFunction

export const handleFileClickDefault: DownloadFileFunction = async (file) => {
  const res = await getFileBlob(file.url)
  if (res?.ok && res.blob) {
    await openBlob(res.blob, file.name ?? 'file.pdf')
  } else {
    handleAndToastError(res?.error, 'failed_to_download_file')
  }
}

export const usePolyfilledDownload: DownloadFileFunctionGenerator = () => {
  const streamSaver = safeWindow?.streamSaver
  const userAgent = safeWindow?.navigator.userAgent
  const needsPolyfill = userAgent ? /(iphone|ipad)/i.test(userAgent) : false

  const [isLoaded, setIsLoaded] = useState(!!streamSaver)

  useEffect(() => {
    if (isLoaded || !needsPolyfill) return

    const script = document.createElement('script')
    script.src = 'https://cdn.jsdelivr.net/npm/streamsaver@2.0.5/StreamSaver.min.js'
    script.async = true
    document.body.appendChild(script)

    const polyfillScript = document.createElement('script')
    polyfillScript.src =
      'https://cdn.jsdelivr.net/npm/web-streams-polyfill@2.0.2/dist/ponyfill.min.js'
    polyfillScript.async = true
    document.body.appendChild(polyfillScript)

    script.onload = () => setIsLoaded(true)
  }, [isLoaded, needsPolyfill])

  if (needsPolyfill && streamSaver) {
    const handleStreamingDownload: DownloadFileFunction = async (file: IUploadedFile) => {
      try {
        const res = await getFileBlob(file.url)
        if (!res?.ok || !res?.blob) {
          throw new ApiError(res)
        }
        const fileStream = streamSaver.createWriteStream(file.name || 'file')
        const readableStream = res.blob.stream()

        if (safeWindow?.WritableStream && readableStream.pipeTo) {
          return readableStream.pipeTo(fileStream)
        }

        const writer = fileStream.getWriter()
        const reader = readableStream.getReader()

        const pump: GenericAsyncFunction = async () => {
          const res = await reader.read()
          if (res.done) {
            await writer.close()
          } else {
            await writer.write(res.value)
            pump()
          }
        }

        pump()
      } catch (err) {
        handleAndToastError(err)
      }
    }

    return handleStreamingDownload
  } else {
    return handleFileClickDefault
  }
}

export const parseFileRejections = (
  t: TFunction<'common', undefined>,
  fileRejections: FileRejection[]
) =>
  fileRejections
    .map((file) =>
      file.errors
        .map((err) => {
          switch (err.code) {
            case 'file-too-large': {
              return t('common.error_codes.image_too_large')
            }
            case 'file-invalid-type': {
              return t('common.error_codes.file_invalid_type_image')
            }
            default:
              return err.message
          }
        })
        .join(', ')
    )
    .join('\n')

export function getFormDataBodyWithFile(
  request: Record<string, unknown>,
  file?: File | ApiType['FileResponse'] | null
) {
  const body = new FormData()
  body.append('request', new Blob([JSON.stringify(request)], { type: 'application/json' }))

  if (file) {
    const blob =
      file instanceof File ? file : new Blob([JSON.stringify(file)], { type: 'application/json' })
    body.append('file', blob)
  }

  return body
}

export async function getFileObjectFromBase64string(data: string, fileName: string, type: string) {
  if (!data) throw Error('Invalid data')
  const res = await fetch(data)
  const blob = await res.blob()
  return new File([blob], fileName, { type })
}

export const useFileWithObjectUrl = (initial?: File | null) => {
  const [file, setFile] = useState(initial)
  const [url, setUrl] = useState<string | null>(null)

  useEffect(() => {
    if (file instanceof File) {
      setUrl(URL.createObjectURL(file))
    } else if (file === null) {
      setUrl(null)
    }

    return () => {
      if (url) {
        URL.revokeObjectURL(url)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return { file, url, setFile }
}

export function describeFileRejections(fileRejections: FileRejection[]) {
  return fileRejections
    .map((file) =>
      file.errors
        .map((err) => {
          switch (err.code) {
            case 'file-invalid-type': {
              return (
                <Translation>{(t) => t('common.error_codes.file_invalid_type_image')}</Translation>
              )
            }
            case 'file-too-large': {
              return <Translation>{(t) => t('common.error_codes.image_too_large')}</Translation>
            }
            case 'too-many-files': {
              return <Translation>{(t) => t('common.error_codes.pick_single_image')}</Translation>
            }
            default:
              // return new Error({ message: err.code })
              return (
                <Translation>{(t) => t('common.error_codes.something_went_wrong')}</Translation>
              )
          }
        })
        .join(', ')
    )
    .join('\n')
}

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { StoryPortraitCategoryFragment } from 'api/contentful/contentful.graphql.types'
import { ContentfulContent } from 'components/content/contentfulContent/ContentfulContent'
import { HorizontalScroll } from 'components/horizontalScroll/HorizontalScroll'
import { Icon } from 'components/icon'
import { Spacer } from 'components/spacer/Spacer'
import { HStack, Stack } from 'components/Stack'
import { ImageCard } from 'components/storyPortraits/cards/ImageCard'
import { Text } from 'components/Typography'
import { useRef } from 'react'
import { colors } from 'utils/colors'
import { matchMap } from 'utils/general'
import { useWindowSize } from 'utils/hooks'
import { BREAKPOINTS, pxToRem, pxToRemUnit } from 'utils/styled'

const SIconButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: var(--card-shadow);
  width: 2.25rem;
  height: 2.25rem;
  color: var(--c-blue500);
  aspect-ratio: 1;
  background-color: white;
`

const DesktopHStack = styled(HStack)`
  display: none;
  @media ${BREAKPOINTS.MD.min} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 6.25rem;
  }
`
const MobileText = styled(Text)`
  font-size: 1.25rem;
  @media ${BREAKPOINTS.MD.min} {
    display: none;
  }
`

const DesktopText = styled(Text)`
  font-size: 1.5rem;
`

export const Category = (props: {
  category: StoryPortraitCategoryFragment
  onImageSelect: (params: { imageName: string; imageId: string; imageUrl: string }) => void
  isModal?: boolean
}) => {
  const { isDesktop } = useWindowSize()
  const scrollRef = useRef<HTMLDivElement>(null)
  const scroll = (direction: 'left' | 'right') => {
    if (!scrollRef.current) return

    const html = document.getElementsByTagName('html')[0]
    const fontSize = html ? window.getComputedStyle(html).fontSize : 16
    const pxs = (pxToRem(292) + 1.5) * parseFloat(String(fontSize))
    const left =
      pxs *
      matchMap(direction, {
        left: -1,
        right: 1,
      })

    scrollRef.current.scrollBy({ left, behavior: 'smooth' })
  }

  const offsetPx = props.isModal ? 32 : isDesktop ? 100 : 16
  return (
    <Stack gap={isDesktop && !props.isModal ? '1.5rem' : '1rem'} accurateSpacers>
      {props.isModal ? (
        <Text
          variant="bodyBold"
          color={colors.gray[100]}
          css={css`
            font-size: 1.25rem;
          `}
        >
          {props.category.title}
        </Text>
      ) : (
        <>
          <MobileText variant="bodyBold" color={colors.gray[100]}>
            {props.category.title}
          </MobileText>

          <Spacer size={0.5} />

          <MobileText css={{ p: { margin: 0 } }}>
            <ContentfulContent data={props.category.description} />
          </MobileText>
          <DesktopHStack>
            <Stack
              gap={'0.5rem'}
              css={css`
                max-width: ${pxToRemUnit(616)};
              `}
            >
              <DesktopText variant="bodyBold" color={colors.gray[100]}>
                {props.category.title}
              </DesktopText>
              <Text css={{ p: { margin: 0 } }}>
                <ContentfulContent data={props.category.description} />
              </Text>
            </Stack>
            <HStack space="0.75rem">
              <SIconButton className="navigator" onClick={() => scroll('left')}>
                <Icon name="ChevronLeft" size="1rem" />
              </SIconButton>
              <SIconButton className="navigator" onClick={() => scroll('right')}>
                <Icon name="ChevronRight" size="1rem" />
              </SIconButton>
            </HStack>
          </DesktopHStack>
        </>
      )}

      <HorizontalScroll
        ref={scrollRef}
        leftOffsetStr={pxToRemUnit(offsetPx)}
        scrollerCss={css`
          overflow-y: hidden;
          padding: 0 ${pxToRemUnit(offsetPx)};
          grid-gap: ${props.isModal || !isDesktop ? '0.5rem' : '1.5rem'};
        `}
      >
        {props.category.picturesCollection?.items.map((item) => {
          const imageId = item?.sys.id
          const imageUrl = item?.url
          if (!imageId || !imageUrl) {
            return null
          }
          return (
            <ImageCard
              key={imageId}
              imageId={imageId}
              imageUrl={imageUrl}
              alt=""
              isModal={props.isModal}
              onClick={() => {
                props.onImageSelect({
                  imageName: String(item.title),
                  imageId,
                  imageUrl,
                })
              }}
            />
          )
        })}
      </HorizontalScroll>
    </Stack>
  )
}

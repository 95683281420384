import styled from '@emotion/styled'
import { Button } from 'components/button/Button'

export const SWebcamWrapper = styled.div`
  width: 100%;
  min-height: 25rem;
  position: relative;
  margin-top: 3rem;
  line-height: 0;
`

export const SImage = styled.img`
  width: 100%;
  height: auto;
`

export const SCheckmarkWrapper = styled.div`
  position: absolute;
  left: -1rem;
  top: -1rem;
`

export const SSelfieMaskContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  & svg {
    height: 100%;
    width: 100%;
  }
`

export const STextWrapper = styled.div`
  margin-top: 1.5rem;
  text-align: center;
`

export const SButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  margin-top: 1.5rem;
`

export const STakePhotoButton = styled(Button)`
  grid-column: 2;
`

import { StoryPortraitLogic } from 'components/storyPortraits/logic'
import { AnalyticsEvent } from 'utils/analytics/event'
import { extractAnalyticsParams } from 'utils/analytics/storyPortraits/extract'

/**
 * when user chooses an image OR uplaods his photo
 */
export const tp_image_selected = new AnalyticsEvent<{ logic: StoryPortraitLogic }>({
  async onFire({ args: { logic }, ...ctx }) {
    const { flow, image_id } = extractAnalyticsParams(logic)
    ctx.fireEvent('tp_image_selected', { flow, image_id })
  },
})

import { StoryPortraitLogic } from 'components/storyPortraits/logic'
import { AnalyticsEvent } from 'utils/analytics/event'
import { extractAnalyticsParams } from 'utils/analytics/storyPortraits/extract'

/**
 * when user clicks on 'start animating' cta after he defined the content (text, voice, phrase)
 */
export const tp_started_animation = new AnalyticsEvent<{
  logic: StoryPortraitLogic
}>({
  async onFire({ args: { logic }, ...ctx }) {
    const { flow, content_id, content_type } = extractAnalyticsParams(logic)
    ctx.fireEvent('tp_started_animation', { flow, content_id, content_type })
  },
})

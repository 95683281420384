import { apiAuthFetcher, apiFetcher } from 'api/goodtrust/api'
import { getUserMe } from 'api/goodtrust/user'
import { checkUserMemoryAnimationReserve, postMemoryRedo } from 'api/goodtrust/user/memory'
import { createFutureMessageWithRedirect } from 'components/futureMessage/FutureMessageUtils'
import { SelectedMemory } from 'components/memory/MemoryUtils'
import { StoryPortraitLogic } from 'components/storyPortraits/logic'
import { handleAndToastError } from 'components/Toast'
import { add, isBefore } from 'date-fns'
import immer from 'immer'
import { describeMyPlans } from 'logic/subscription/plan/my/describe'
import Router from 'next/router'
import { isUserLoggedIn } from 'utils/auth/authFetcher'
import { bindEveryMethod } from 'utils/class'
import { assumeUTCTime } from 'utils/date'
import { expectAlwaysNonNullable } from 'utils/error'
import { encodeQuery, unwrapResponse } from 'utils/fetcher'
import { numberComparator } from 'utils/general'
import { ApiType } from 'utils/types'

export class ExtendedStoryPortraitMemory {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public readonly response: ApiType['MemoryResponse'],
    public animatedFileUuid: string | undefined
  ) {
    bindEveryMethod(this)
  }

  async onSendAsFutureMessageClick() {
    const memory = this.selectAnimation()
    await createFutureMessageWithRedirect({
      fetcher: await isUserLoggedIn().then((yes) => (yes ? apiAuthFetcher : apiFetcher)),
      type: 'share',
      redirectParams: {
        memoryType: 'video',
        memoryFileUrl: memory?.selectedAnimation?.file?.url,
      },
    })
  }

  urls() {
    return {
      result: encodeQuery(`/story-portraits/${this.response.uuid}`, {
        animationUuid: this.animatedFileUuid,
      }),
    }
  }

  selectAnimation(): SelectedMemory | undefined {
    const selectedAnimation = this.response.animated_files?.find(
      (file) =>
        file.animation_type === 'STORY_PORTRAIT' &&
        file.uuid != null &&
        file.uuid === this.animatedFileUuid
    )
    if (!selectedAnimation) return undefined
    return {
      ...this.response,
      selectedAnimation,
    }
  }

  delayed(startTime: number, now: number) {
    const isBeingAnimated =
      StoryPortraitLogic.latest.animation?.memoryId === this.response.uuid &&
      this.response.uuid != null

    if (!isBeingAnimated) return this.response

    return immer(this.response, (st) => {
      const file = st.animated_files?.find((file) => file.animation_type === 'STORY_PORTRAIT')

      const earliestFinishAt = add(startTime, { seconds: 6 })
      const expectedFinishAt = add(startTime, {
        seconds: file?.story_portrait?.expected_seconds_to_take ?? 0,
      })

      const shouldDelayFinished = isBefore(now, earliestFinishAt)

      if (shouldDelayFinished && file?.story_portrait) {
        file.status = 'STARTED'
        file.story_portrait.animation_started_at = new Date(startTime).toISOString()
        file.story_portrait.expected_seconds_to_take =
          (Math.max(expectedFinishAt.getTime(), earliestFinishAt.getTime()) - startTime) / 1000
      }
    })
  }

  describeAnimationState() {
    const animatedFile = this.selectAnimation()?.selectedAnimation
    const status = animatedFile?.status
    const isAnimating = status === 'STARTED' || status === 'CREATED'
    const isReady = status === 'DONE'
    const isFailed = status === 'ERROR' || status === 'TIMEOUT'

    const expectedFinishAt =
      animatedFile?.story_portrait?.animation_started_at &&
      animatedFile.story_portrait.expected_seconds_to_take
        ? add(new Date(assumeUTCTime(animatedFile.story_portrait.animation_started_at)), {
            seconds: animatedFile.story_portrait.expected_seconds_to_take,
          }).toISOString()
        : undefined

    const startedAt = animatedFile?.story_portrait?.animation_started_at
      ? new Date(assumeUTCTime(animatedFile.story_portrait.animation_started_at)).toISOString()
      : undefined

    const animationTiming =
      isAnimating && expectedFinishAt != null && startedAt != null
        ? { startedAt, expectedFinishAt }
        : undefined

    return {
      status,
      isAnimating,
      isReady,
      isFailed,
      animationTiming,
    }
  }

  getFileToDownload() {
    return this.selectAnimation()?.selectedAnimation?.file
  }

  onRemoveWatermarkClick() {
    checkUserMemoryAnimationReserve()
      .then(unwrapResponse.body)
      .then(async (reserve) => {
        const isOnAnimationSubscription = await getUserMe()
          .then(unwrapResponse.body)
          .then(describeMyPlans)
          .then((d) => d.forPlanGroup('ANIMATION').hasPaidPlan())
        // we should remove the watermark immediately if the user has an animation credit in the reserve we can use
        // in case they don't have it, we hit them with the paywall
        const shouldRemoveWatermark = isOnAnimationSubscription
          ? !!reserve.reserve || !!reserve.free
          : !!reserve.reserve
        if (shouldRemoveWatermark) {
          // request the recreation of the memory resulting into the removal of the watermark
          await this.onWatermarkShouldBeRemoved()
        } else {
          // hit them with the paywall
          StoryPortraitLogic.latest.onUpgradeRequired({
            memoryUuid: expectAlwaysNonNullable(this.response.uuid),
            removeWatermark: {
              animationUuid: expectAlwaysNonNullable(this.animatedFileUuid),
            },
          })
        }
      })
      .catch(handleAndToastError)
  }

  async onWatermarkShouldBeRemoved() {
    if (!this.canRemoveWatermark()) {
      Router.push(this.urls().result)
      return
    }
    const selected = this.selectAnimation()
    const memoryUuid = String(this.response.uuid)

    const memory = await postMemoryRedo(memoryUuid, {
      driver_type: selected?.selectedAnimation?.driver_type,
      story_portrait_uuid: selected?.selectedAnimation?.story_portrait?.uuid,
    }).then(unwrapResponse.body)

    Router.push(
      new ExtendedStoryPortraitMemory(memory, undefined).pickLatestAnimatedFile().urls().result
    )
  }

  canRemoveWatermark() {
    return !!this.selectAnimation()?.selectedAnimation?.has_watermark
  }

  latestAnimatedFile() {
    return this.response.animated_files
      ?.slice()
      .sort(
        numberComparator((file) =>
          file.story_portrait?.animation_started_at
            ? -new Date(file.story_portrait.animation_started_at).getTime()
            : Infinity
        )
      )[0]
  }

  pickLatestAnimatedFile() {
    return new ExtendedStoryPortraitMemory(this.response, this.latestAnimatedFile()?.uuid)
  }
}

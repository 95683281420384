import { StoryPortraitLogic } from 'components/storyPortraits/logic'
import { AnalyticsEvent } from 'utils/analytics/event'
import { extractAnalyticsParams } from 'utils/analytics/storyPortraits/extract'

/**
 * when user passes the login wall = signs up or logs in
 */
export const tp_login_wall_passed = new AnalyticsEvent<{ logic: StoryPortraitLogic }>({
  async onFire({ args: { logic }, ...ctx }) {
    const { flow, image_id, content_id } = extractAnalyticsParams(logic)
    ctx.fireEvent('tp_login_wall_passed', {
      flow,
      content_id,
      image_id,
    })
  },
})

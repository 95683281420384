import { fetcher } from './api'
import { MessageException } from 'utils/error'
import { FetchArgs } from 'utils/fetcher'

export async function getFileBlob(
  url?: string,
  signal?: AbortSignal | null,
  fetchArgs?: FetchArgs
) {
  if (!url) throw new MessageException('failed_to_download_file')
  return fetcher(url, {
    ...fetchArgs,
    toBlob: true,
    noContentTypeHeader: true,
    signal,
  })
}

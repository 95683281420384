import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { GetStoryPortraitCategoriesQuery } from 'api/contentful/contentful.graphql.types'
import { Only } from 'components/only/Only'
import { Stack } from 'components/Stack'
import { Category } from 'components/storyPortraits/category/Category'
import { useStoryPortraitLogic } from 'components/storyPortraits/logic'
import { Caption } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import { useWindowSize } from 'utils/hooks'
import { BREAKPOINTS, prettierScrollbarCss } from 'utils/styled'
import { ApiType } from 'utils/types'

const SSection = styled.section<{ isModal?: boolean }>`
  ${prettierScrollbarCss}
  ${({ isModal }) => css`
    max-height: ${isModal ? '60vh' : 'unset'};
    overflow-y: ${isModal ? 'scroll' : 'unset'};
  `}
`

const SCaption = styled(Caption)`
  margin-bottom: 1.25rem;
  @media ${BREAKPOINTS.MD.min} {
    margin-bottom: 1.5rem;
  }
`

export const CategorySection = (props: {
  isModal?: boolean
  categories: GetStoryPortraitCategoriesQuery
  userMemories?: ApiType['MemoryResponse'][]
  onImageSelected?: () => void
  noPadding?: boolean
}) => {
  const { t } = useTranslation('memory')
  const { isDesktop } = useWindowSize()
  const logic = useStoryPortraitLogic()

  const onImageSelect = (params: { imageName: string; imageId: string; imageUrl: string }) => {
    props.onImageSelected?.()
    logic.onGallerySectionPictureSelected(params.imageName, params.imageId, params.imageUrl)
  }

  return (
    <SSection isModal={props.isModal} css={props.noPadding ? { padding: 0 } : undefined}>
      {!props.isModal && (
        <>
          <Only on="mobile">
            <SCaption>{t('memory.story.categories_caption')}</SCaption>
          </Only>
          <Only on="desktop">
            <SCaption>{t('memory.story.categories_caption_longer')}</SCaption>
          </Only>
        </>
      )}
      <Stack gap={props.isModal ? '1.5rem' : isDesktop ? '3rem' : '1.25rem'}>
        {!!props.userMemories?.length && (
          <Category
            isModal={props.isModal}
            onImageSelect={onImageSelect}
            category={{
              sys: {
                id: 'userMemorySection',
              },
              title: t('memory.story.categories_your_uploads_title'),
              picturesCollection: {
                items: props.userMemories.map((memory) => ({
                  sys: {
                    id: 'userMemory/' + memory.uuid,
                  },
                  url: memory.original_file?.thumbnail_url,
                })),
              },
            }}
          />
        )}
        {props.categories.memorySetupCollection?.items[0]?.storyPortraitCategoriesCollection?.items.map(
          (category) => {
            return category ? (
              <Category
                key={category.sys.id}
                isModal={props.isModal}
                category={category}
                onImageSelect={onImageSelect}
              />
            ) : null
          }
        )}
      </Stack>
    </SSection>
  )
}

import { AnalyticsEvent } from 'utils/analytics/event'
import { EventArgs } from 'utils/analytics/types'

/**
 * when user clicks on a cta that takes him to the GT gallery OR when user clicks on a cta that lets him upload his photo - can be done on a landing page, on the result page, maybe even during the flow
 */
export const tp_start_cta_clicked = new AnalyticsEvent<EventArgs['tp_start_cta_clicked']>({
  async onFire({ args: { flow }, ...ctx }) {
    ctx.fireEvent('tp_start_cta_clicked', { flow })
  },
})

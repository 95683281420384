import { AnalyticsEvent } from 'utils/analytics/event'
import { EventArgs } from 'utils/analytics/types'

/**
 * when user clicks on any of the sharing options - email, facebook, link, whatsapp etc
 */
export const tp_shared = new AnalyticsEvent<EventArgs['tp_shared']>({
  async onFire({ args, ...ctx }) {
    ctx.fireEvent('tp_shared', args)
  },
})

import { StoryPortraitLogic } from 'components/storyPortraits/logic'
import { AnalyticsEvent } from 'utils/analytics/event'
import { extractAnalyticsParams } from 'utils/analytics/storyPortraits/extract'

/**
 * when user hits the paywall
 */
export const tp_paywall_hit = new AnalyticsEvent<{ logic: StoryPortraitLogic }>({
  async onFire(ctx) {
    const { flow, image_id, content_id } = extractAnalyticsParams(ctx.args.logic)
    ctx.fireEvent('tp_paywall_hit', {
      content_id,
      flow,
      image_id,
    })
  },
})

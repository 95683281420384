import { StoryPortraitLogic } from 'components/storyPortraits/logic'
import { AnalyticsEvent } from 'utils/analytics/event'
import { sortEventHistory } from 'utils/analytics/history'
import { extractAnalyticsParams } from 'utils/analytics/storyPortraits/extract'

/**
 * when the animaiton is finshed = user is on the final screen,
 * animation is ready and it is unlocked
 */
export const tp_finished = new AnalyticsEvent<{ logic: StoryPortraitLogic }>({
  async onFire({ args: { logic }, ...ctx }) {
    const { flow, image_id, content_id } = extractAnalyticsParams(logic)

    const [paywall_status = 'no_paywall'] = sortEventHistory(ctx.history, [
      'tp_paywall_hit',
      'tp_started_animation',
    ]).map((x) => (x === 'tp_paywall_hit' ? 'with_paywall' : 'no_paywall'))
    const [paid = 'no'] = sortEventHistory(ctx.history, [
      'tp_paywall_passed',
      'tp_started_animation',
    ]).map((x) => (x === 'tp_paywall_passed' ? 'yes' : 'no'))

    ctx.fireEvent('tp_finished', {
      flow,
      content_id,
      image_id,
      paywall_status,
      paid,
    })
  },
})

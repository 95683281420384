import React from 'react'
import styled from '@emotion/styled'
import { encodeQuery } from 'utils/fetcher'
import { Maybe } from '@graphql-tools/utils'
import { BREAKPOINTS, pxToRemUnit } from 'utils/styled'
import { css } from '@emotion/react'
import { Stack } from 'components/Stack'
import { Text } from 'components/Typography'
import { colors } from 'utils/colors'
import { useTranslation } from 'next-i18next'

const SStoryPortraitImage = styled.img<{ isModal: boolean; isMobileCarousel: boolean }>`
  ${({ isModal, isMobileCarousel }) => css`
    object-fit: ${isModal ? 'contain' : 'cover'};
    object-position: center;
    height: ${isMobileCarousel ? pxToRemUnit(210) : pxToRemUnit(140)};
    width: ${isModal ? 'auto' : isMobileCarousel ? pxToRemUnit(280) : pxToRemUnit(140)};
    border-radius: ${isMobileCarousel ? '0.75rem' : '0.25rem'};

    @media ${BREAKPOINTS.MD.min} {
      border-radius: ${isModal ? '0.5rem' : '0.75rem'};
      height: ${isModal ? pxToRemUnit(140) : pxToRemUnit(269)};
      width: ${isModal ? 'auto' : pxToRemUnit(292)};
    }
  `}
`

const SWrapper = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  &:hover {
    cursor: pointer;
  }
  @media ${BREAKPOINTS.MD.min} {
    &:hover {
      .add-voice {
        visibility: visible;
      }
    }
  }
`

const SHover = styled(Stack)`
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2rem;
  padding: 5px 1.5rem;
  background-color: var(--c-white);
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.15);
  visibility: hidden;
  &:hover {
    cursor: pointer;
  }
`

export const ImageCard = (props: {
  onClick?: () => void
  imageId?: string
  imageUrl?: Maybe<string>
  alt: string
  isModal?: boolean
  heroCarousel?: boolean
}) => {
  const { t } = useTranslation('memory')

  const imageUrl = props.imageUrl
  if (!imageUrl) return null

  return (
    <SWrapper onClick={props.onClick}>
      <SStoryPortraitImage
        isModal={!!props.isModal}
        isMobileCarousel={!!props.heroCarousel}
        alt={props.alt}
        src={encodeQuery(imageUrl, {
          ...(!props.isModal && { w: 292 }),
          h: 269,
          ...(props.heroCarousel && { w: 280, h: 210 }),
          fit: 'thumb',
        })}
      />
      {!props.isModal && (
        <SHover className="add-voice">
          <Text color={colors.black}>{t('memory.story.image_create_story_button')}</Text>
        </SHover>
      )}
    </SWrapper>
  )
}

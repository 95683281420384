import { StoryPortraitLogic } from 'components/storyPortraits/logic'
import { AnalyticsEvent } from 'utils/analytics/event'
import { extractAnalyticsParams } from 'utils/analytics/storyPortraits/extract'

/**
 * when user passes the paywall = buys something = order and payment are successful and confirmed
 */
export const tp_paywall_passed = new AnalyticsEvent<{ logic: StoryPortraitLogic }>({
  async onFire(ctx) {
    const { content_id, flow, image_id } = extractAnalyticsParams(ctx.args.logic)

    ctx.fireEvent('tp_paywall_passed', {
      content_id,
      flow,
      image_id,
      product: ctx.history.tp_paywall_product_selected?.product ?? 'unknown',
    })
  },
})

export const extractedLanguages = [
  {
    code: 'af-ZA',
    name: 'Afrikaans (South Africa)',
    voices: ['af-ZA-AdriNeural', 'af-ZA-WillemNeural'],
  },
  {
    code: 'am-ET',
    name: 'Amharic (Ethiopia)',
    voices: ['am-ET-AmehaNeural', 'am-ET-MekdesNeural'],
  },
  {
    code: 'ar-AE',
    name: 'Arabic (United Arab Emirates)',
    voices: ['ar-AE-FatimaNeural', 'ar-AE-HamdanNeural'],
  },
  {
    code: 'ar-BH',
    name: 'Arabic (Bahrain)',
    voices: ['ar-BH-AliNeural', 'ar-BH-LailaNeural'],
  },
  {
    code: 'ar-DZ',
    name: 'Arabic (Algeria)',
    voices: ['ar-DZ-AminaNeural', 'ar-DZ-IsmaelNeural'],
  },
  {
    code: 'ar-EG',
    name: 'Arabic (Egypt)',
    voices: ['ar-EG-SalmaNeural', 'ar-EG-ShakirNeural'],
  },
  {
    code: 'ar-IQ',
    name: 'Arabic (Iraq)',
    voices: ['ar-IQ-BasselNeural', 'ar-IQ-RanaNeural'],
  },
  {
    code: 'ar-JO',
    name: 'Arabic (Jordan)',
    voices: ['ar-JO-SanaNeural', 'ar-JO-TaimNeural'],
  },
  {
    code: 'ar-KW',
    name: 'Arabic (Kuwait)',
    voices: ['ar-KW-FahedNeural', 'ar-KW-NouraNeural'],
  },
  {
    code: 'ar-LB',
    name: 'Arabic (Lebanon)',
    voices: ['ar-LB-LaylaNeural', 'ar-LB-RamiNeural'],
  },
  {
    code: 'ar-LY',
    name: 'Arabic (Libya)',
    voices: ['ar-LY-ImanNeural', 'ar-LY-OmarNeural'],
  },
  {
    code: 'ar-MA',
    name: 'Arabic (Morocco)',
    voices: ['ar-MA-JamalNeural', 'ar-MA-MounaNeural'],
  },
  {
    code: 'ar-OM',
    name: 'Arabic (Oman)',
    voices: ['ar-OM-AbdullahNeural', 'ar-OM-AyshaNeural'],
  },
  {
    code: 'ar-QA',
    name: 'Arabic (Qatar)',
    voices: ['ar-QA-AmalNeural', 'ar-QA-MoazNeural'],
  },
  {
    code: 'ar-SA',
    name: 'Arabic (Saudi Arabia)',
    voices: ['ar-SA-HamedNeural', 'ar-SA-ZariyahNeural'],
  },
  {
    code: 'ar-SY',
    name: 'Arabic (Syria)',
    voices: ['ar-SY-AmanyNeural', 'ar-SY-LaithNeural'],
  },
  {
    code: 'ar-TN',
    name: 'Arabic (Tunisia)',
    voices: ['ar-TN-HediNeural', 'ar-TN-ReemNeural'],
  },
  {
    code: 'ar-YE',
    name: 'Arabic (Yemen)',
    voices: ['ar-YE-MaryamNeural', 'ar-YE-SalehNeural'],
  },
  {
    code: 'az-AZ',
    name: 'Azerbaijani (Azerbaijan)',
    voices: ['az-AZ-BabekNeural', 'az-AZ-BanuNeural'],
  },
  {
    code: 'bg-BG',
    name: 'Bulgarian (Bulgaria)',
    voices: ['bg-BG-BorislavNeural', 'bg-BG-KalinaNeural'],
  },
  {
    code: 'bn-BD',
    name: 'Bangla (Bangladesh)',
    voices: ['bn-BD-NabanitaNeural', 'bn-BD-PradeepNeural'],
  },
  {
    code: 'bn-IN',
    name: 'Bengali (India)',
    voices: ['bn-IN-BashkarNeural', 'bn-IN-TanishaaNeural'],
  },
  {
    code: 'bs-BA',
    name: 'Bosnian (Bosnia)',
    voices: ['bs-BA-GoranNeural', 'bs-BA-VesnaNeural'],
  },
  {
    code: 'ca-ES',
    name: 'Catalan (Spain)',
    voices: ['ca-ES-AlbaNeural', 'ca-ES-EnricNeural', 'ca-ES-JoanaNeural'],
  },
  {
    code: 'cs-CZ',
    name: 'Czech (Czech)',
    voices: ['cs-CZ-AntoninNeural', 'cs-CZ-VlastaNeural'],
  },
  {
    code: 'cy-GB',
    name: 'Welsh (United Kingdom)',
    voices: ['cy-GB-AledNeural', 'cy-GB-NiaNeural'],
  },
  {
    code: 'da-DK',
    name: 'Danish (Denmark)',
    voices: ['da-DK-ChristelNeural', 'da-DK-JeppeNeural'],
  },
  {
    code: 'de-AT',
    name: 'German (Austria)',
    voices: ['de-AT-IngridNeural', 'de-AT-JonasNeural'],
  },
  {
    code: 'de-CH',
    name: 'German (Switzerland)',
    voices: ['de-CH-JanNeural', 'de-CH-LeniNeural'],
  },
  {
    code: 'de-DE',
    name: 'German (Germany)',
    voices: [
      'de-DE-AmalaNeural',
      'de-DE-BerndNeural',
      'de-DE-ChristophNeural',
      'de-DE-ConradNeural',
      'de-DE-ElkeNeural',
      'de-DE-GiselaNeural',
      'de-DE-KasperNeural',
      'de-DE-KatjaNeural',
      'de-DE-KillianNeural',
      'de-DE-KlarissaNeural',
      'de-DE-KlausNeural',
      'de-DE-LouisaNeural',
      'de-DE-MajaNeural',
      'de-DE-RalfNeural',
      'de-DE-TanjaNeural',
    ],
  },
  {
    code: 'el-GR',
    name: 'Greek (Greece)',
    voices: ['el-GR-AthinaNeural', 'el-GR-NestorasNeural'],
  },
  {
    code: 'en-AU',
    name: 'English (Australia)',
    voices: [
      'en-AU-AnnetteNeural',
      'en-AU-CarlyNeural',
      'en-AU-DarrenNeural',
      'en-AU-DuncanNeural',
      'en-AU-ElsieNeural',
      'en-AU-FreyaNeural',
      'en-AU-JoanneNeural',
      'en-AU-KenNeural',
      'en-AU-KimNeural',
      'en-AU-NatashaNeural',
      'en-AU-NeilNeural',
      'en-AU-TimNeural',
      'en-AU-TinaNeural',
      'en-AU-WilliamNeural',
    ],
  },
  {
    code: 'en-CA',
    name: 'English (Canada)',
    voices: ['en-CA-ClaraNeural', 'en-CA-LiamNeural'],
  },
  {
    code: 'en-GB',
    name: 'English (United Kingdom)',
    voices: [
      'en-GB-AbbiNeural',
      'en-GB-AlfieNeural',
      'en-GB-BellaNeural',
      'en-GB-ElliotNeural',
      'en-GB-EthanNeural',
      'en-GB-HollieNeural',
      'en-GB-LibbyNeural',
      'en-GB-MaisieNeural',
      'en-GB-NoahNeural',
      'en-GB-OliverNeural',
      'en-GB-OliviaNeural',
      'en-GB-RyanNeural',
      'en-GB-SoniaNeural',
      'en-GB-ThomasNeural',
    ],
  },
  {
    code: 'en-GH',
    name: 'English (Ghana)',
    voices: [],
  },
  {
    code: 'en-HK',
    name: 'English (Hongkong)',
    voices: ['en-HK-SamNeural', 'en-HK-YanNeural'],
  },
  {
    code: 'en-IE',
    name: 'English (Ireland)',
    voices: ['en-IE-ConnorNeural', 'en-IE-EmilyNeural'],
  },
  {
    code: 'en-IN',
    name: 'English (India)',
    voices: ['en-IN-NeerjaNeural', 'en-IN-PrabhatNeural'],
  },
  {
    code: 'en-KE',
    name: 'English (Kenya)',
    voices: ['en-KE-AsiliaNeural', 'en-KE-ChilembaNeural'],
  },
  {
    code: 'en-NG',
    name: 'English (Nigeria)',
    voices: ['en-NG-AbeoNeural', 'en-NG-EzinneNeural'],
  },
  {
    code: 'en-NZ',
    name: 'English (New Zealand)',
    voices: ['en-NZ-MitchellNeural', 'en-NZ-MollyNeural'],
  },
  {
    code: 'en-PH',
    name: 'English (Philippines)',
    voices: ['en-PH-JamesNeural', 'en-PH-RosaNeural'],
  },
  {
    code: 'en-SG',
    name: 'English (Singapore)',
    voices: ['en-SG-LunaNeural', 'en-SG-WayneNeural'],
  },
  {
    code: 'en-TZ',
    name: 'English (Tanzania)',
    voices: ['en-TZ-ElimuNeural', 'en-TZ-ImaniNeural'],
  },
  {
    code: 'en-US',
    name: 'English (United States)',
    voices: [
      'en-US-AIGenerate1Neural',
      'en-US-AIGenerate2Neural',
      'en-US-AmberNeural',
      'en-US-AnaNeural',
      'en-US-AriaNeural',
      'en-US-AshleyNeural',
      'en-US-BrandonNeural',
      'en-US-ChristopherNeural',
      'en-US-CoraNeural',
      'en-US-DavisNeural',
      'en-US-ElizabethNeural',
      'en-US-EricNeural',
      'en-US-GuyNeural',
      'en-US-JacobNeural',
      'en-US-JaneNeural',
      'en-US-JasonNeural',
      'en-US-JennyMultilingualNeural',
      'en-US-JennyNeural',
      'en-US-MichelleNeural',
      'en-US-MonicaNeural',
      'en-US-NancyNeural',
      'en-US-RogerNeural',
      'en-US-SaraNeural',
      'en-US-SteffanNeural',
      'en-US-TonyNeural',
    ],
  },
  {
    code: 'en-ZA',
    name: 'English (South Africa)',
    voices: ['en-ZA-LeahNeural', 'en-ZA-LukeNeural'],
  },
  {
    code: 'es-AR',
    name: 'Spanish (Argentina)',
    voices: ['es-AR-ElenaNeural', 'es-AR-TomasNeural'],
  },
  {
    code: 'es-BO',
    name: 'Spanish (Bolivia)',
    voices: ['es-BO-MarceloNeural', 'es-BO-SofiaNeural'],
  },
  {
    code: 'es-CL',
    name: 'Spanish (Chile)',
    voices: ['es-CL-CatalinaNeural', 'es-CL-LorenzoNeural'],
  },
  {
    code: 'es-CO',
    name: 'Spanish (Colombia)',
    voices: ['es-CO-GonzaloNeural', 'es-CO-SalomeNeural'],
  },
  {
    code: 'es-CR',
    name: 'Spanish (Costa Rica)',
    voices: ['es-CR-JuanNeural', 'es-CR-MariaNeural'],
  },
  {
    code: 'es-CU',
    name: 'Spanish (Cuba)',
    voices: ['es-CU-BelkysNeural', 'es-CU-ManuelNeural'],
  },
  {
    code: 'es-DO',
    name: 'Spanish (Dominican Republic)',
    voices: ['es-DO-EmilioNeural', 'es-DO-RamonaNeural'],
  },
  {
    code: 'es-EC',
    name: 'Spanish (Ecuador)',
    voices: ['es-EC-AndreaNeural', 'es-EC-LuisNeural'],
  },
  {
    code: 'es-ES',
    name: 'Spanish (Spain)',
    voices: [
      'es-ES-AbrilNeural',
      'es-ES-AlvaroNeural',
      'es-ES-ArnauNeural',
      'es-ES-DarioNeural',
      'es-ES-EliasNeural',
      'es-ES-ElviraNeural',
      'es-ES-EstrellaNeural',
      'es-ES-IreneNeural',
      'es-ES-LaiaNeural',
      'es-ES-LiaNeural',
      'es-ES-NilNeural',
      'es-ES-SaulNeural',
      'es-ES-TeoNeural',
      'es-ES-TrianaNeural',
      'es-ES-VeraNeural',
    ],
  },
  {
    code: 'es-GQ',
    name: 'Spanish (Equatorial Guinea)',
    voices: ['es-GQ-JavierNeural', 'es-GQ-TeresaNeural'],
  },
  {
    code: 'es-GT',
    name: 'Spanish (Guatemala)',
    voices: ['es-GT-AndresNeural', 'es-GT-MartaNeural'],
  },
  {
    code: 'es-HN',
    name: 'Spanish (Honduras)',
    voices: ['es-HN-CarlosNeural', 'es-HN-KarlaNeural'],
  },
  {
    code: 'es-MX',
    name: 'Spanish (Mexico)',
    voices: [
      'es-MX-BeatrizNeural',
      'es-MX-CandelaNeural',
      'es-MX-CarlotaNeural',
      'es-MX-CecilioNeural',
      'es-MX-DaliaNeural',
      'es-MX-GerardoNeural',
      'es-MX-JorgeNeural',
      'es-MX-LarissaNeural',
      'es-MX-LibertoNeural',
      'es-MX-LucianoNeural',
      'es-MX-MarinaNeural',
      'es-MX-NuriaNeural',
      'es-MX-PelayoNeural',
      'es-MX-RenataNeural',
      'es-MX-YagoNeural',
    ],
  },
  {
    code: 'es-NI',
    name: 'Spanish (Nicaragua)',
    voices: ['es-NI-FedericoNeural', 'es-NI-YolandaNeural'],
  },
  {
    code: 'es-PA',
    name: 'Spanish (Panama)',
    voices: ['es-PA-MargaritaNeural', 'es-PA-RobertoNeural'],
  },
  {
    code: 'es-PE',
    name: 'Spanish (Peru)',
    voices: ['es-PE-AlexNeural', 'es-PE-CamilaNeural'],
  },
  {
    code: 'es-PR',
    name: 'Spanish (Puerto Rico)',
    voices: ['es-PR-KarinaNeural', 'es-PR-VictorNeural'],
  },
  {
    code: 'es-PY',
    name: 'Spanish (Paraguay)',
    voices: ['es-PY-MarioNeural', 'es-PY-TaniaNeural'],
  },
  {
    code: 'es-SV',
    name: 'Spanish (El Salvador)',
    voices: ['es-SV-LorenaNeural', 'es-SV-RodrigoNeural'],
  },
  {
    code: 'es-US',
    name: 'Spanish (United States)',
    voices: ['es-US-AlonsoNeural', 'es-US-PalomaNeural'],
  },
  {
    code: 'es-UY',
    name: 'Spanish (Uruguay)',
    voices: ['es-UY-MateoNeural', 'es-UY-ValentinaNeural'],
  },
  {
    code: 'es-VE',
    name: 'Spanish (Venezuela)',
    voices: ['es-VE-PaolaNeural', 'es-VE-SebastianNeural'],
  },
  {
    code: 'et-EE',
    name: 'Estonian (Estonia)',
    voices: ['et-EE-AnuNeural', 'et-EE-KertNeural'],
  },
  {
    code: 'eu-ES',
    name: 'Basque',
    voices: ['eu-ES-AinhoaNeural', 'eu-ES-AnderNeural'],
  },
  {
    code: 'fa-IR',
    name: 'Persian (Iran)',
    voices: ['fa-IR-DilaraNeural', 'fa-IR-FaridNeural'],
  },
  {
    code: 'fi-FI',
    name: 'Finnish (Finland)',
    voices: ['fi-FI-HarriNeural', 'fi-FI-NooraNeural', 'fi-FI-SelmaNeural'],
  },
  {
    code: 'fil-PH',
    name: 'Filipino (Philippines)',
    voices: ['fil-PH-AngeloNeural', 'fil-PH-BlessicaNeural'],
  },
  {
    code: 'fr-BE',
    name: 'French (Belgium)',
    voices: ['fr-BE-CharlineNeural', 'fr-BE-GerardNeural'],
  },
  {
    code: 'fr-CA',
    name: 'French (Canada)',
    voices: ['fr-CA-AntoineNeural', 'fr-CA-JeanNeural', 'fr-CA-SylvieNeural'],
  },
  {
    code: 'fr-CH',
    name: 'French (Switzerland)',
    voices: ['fr-CH-ArianeNeural', 'fr-CH-FabriceNeural'],
  },
  {
    code: 'fr-FR',
    name: 'French (France)',
    voices: [
      'fr-FR-AlainNeural',
      'fr-FR-BrigitteNeural',
      'fr-FR-CelesteNeural',
      'fr-FR-ClaudeNeural',
      'fr-FR-CoralieNeural',
      'fr-FR-DeniseNeural',
      'fr-FR-EloiseNeural',
      'fr-FR-HenriNeural',
      'fr-FR-JacquelineNeural',
      'fr-FR-JeromeNeural',
      'fr-FR-JosephineNeural',
      'fr-FR-MauriceNeural',
      'fr-FR-YvesNeural',
      'fr-FR-YvetteNeural',
    ],
  },
  {
    code: 'ga-IE',
    name: 'Irish (Ireland)',
    voices: ['ga-IE-ColmNeural', 'ga-IE-OrlaNeural'],
  },
  {
    code: 'gl-ES',
    name: 'Galician',
    voices: ['gl-ES-RoiNeural', 'gl-ES-SabelaNeural'],
  },
  {
    code: 'gu-IN',
    name: 'Gujarati (India)',
    voices: ['gu-IN-DhwaniNeural', 'gu-IN-NiranjanNeural'],
  },
  {
    code: 'he-IL',
    name: 'Hebrew (Israel)',
    voices: ['he-IL-AvriNeural', 'he-IL-HilaNeural'],
  },
  {
    code: 'hi-IN',
    name: 'Hindi (India)',
    voices: ['hi-IN-MadhurNeural', 'hi-IN-SwaraNeural'],
  },
  {
    code: 'hr-HR',
    name: 'Croatian (Croatia)',
    voices: ['hr-HR-GabrijelaNeural', 'hr-HR-SreckoNeural'],
  },
  {
    code: 'hu-HU',
    name: 'Hungarian (Hungary)',
    voices: ['hu-HU-NoemiNeural', 'hu-HU-TamasNeural'],
  },
  {
    code: 'hy-AM',
    name: 'Armenian (Armenia)',
    voices: ['hy-AM-AnahitNeural', 'hy-AM-HaykNeural'],
  },
  {
    code: 'id-ID',
    name: 'Indonesian (Indonesia)',
    voices: ['id-ID-ArdiNeural', 'id-ID-GadisNeural'],
  },
  {
    code: 'is-IS',
    name: 'Icelandic (Iceland)',
    voices: ['is-IS-GudrunNeural', 'is-IS-GunnarNeural'],
  },
  {
    code: 'it-IT',
    name: 'Italian (Italy)',
    voices: [
      'it-IT-BenignoNeural',
      'it-IT-CalimeroNeural',
      'it-IT-CataldoNeural',
      'it-IT-DiegoNeural',
      'it-IT-ElsaNeural',
      'it-IT-FabiolaNeural',
      'it-IT-FiammaNeural',
      'it-IT-GianniNeural',
      'it-IT-ImeldaNeural',
      'it-IT-IrmaNeural',
      'it-IT-IsabellaNeural',
      'it-IT-LisandroNeural',
      'it-IT-PalmiraNeural',
      'it-IT-PierinaNeural',
      'it-IT-RinaldoNeural',
    ],
  },
  {
    code: 'ja-JP',
    name: 'Japanese (Japan)',
    voices: [
      'ja-JP-AoiNeural',
      'ja-JP-DaichiNeural',
      'ja-JP-KeitaNeural',
      'ja-JP-MayuNeural',
      'ja-JP-NanamiNeural',
      'ja-JP-NaokiNeural',
      'ja-JP-ShioriNeural',
    ],
  },
  {
    code: 'jv-ID',
    name: 'Javanese (Indonesia)',
    voices: ['jv-ID-DimasNeural', 'jv-ID-SitiNeural'],
  },
  {
    code: 'ka-GE',
    name: 'Georgian (Georgia)',
    voices: ['ka-GE-EkaNeural', 'ka-GE-GiorgiNeural'],
  },
  {
    code: 'kk-KZ',
    name: 'Kazakh (Kazakhstan)',
    voices: ['kk-KZ-AigulNeural', 'kk-KZ-DauletNeural'],
  },
  {
    code: 'km-KH',
    name: 'Khmer (Cambodia)',
    voices: ['km-KH-PisethNeural', 'km-KH-SreymomNeural'],
  },
  {
    code: 'kn-IN',
    name: 'Kannada (India)',
    voices: ['kn-IN-GaganNeural', 'kn-IN-SapnaNeural'],
  },
  {
    code: 'ko-KR',
    name: 'Korean (Korea)',
    voices: [
      'ko-KR-BongJinNeural',
      'ko-KR-GookMinNeural',
      'ko-KR-InJoonNeural',
      'ko-KR-JiMinNeural',
      'ko-KR-SeoHyeonNeural',
      'ko-KR-SoonBokNeural',
      'ko-KR-SunHiNeural',
      'ko-KR-YuJinNeural',
    ],
  },
  {
    code: 'lo-LA',
    name: 'Lao (Laos)',
    voices: ['lo-LA-ChanthavongNeural', 'lo-LA-KeomanyNeural'],
  },
  {
    code: 'lt-LT',
    name: 'Lithuanian (Lithuania)',
    voices: ['lt-LT-LeonasNeural', 'lt-LT-OnaNeural'],
  },
  {
    code: 'lv-LV',
    name: 'Latvian (Latvia)',
    voices: ['lv-LV-EveritaNeural', 'lv-LV-NilsNeural'],
  },
  {
    code: 'mk-MK',
    name: 'Macedonian (Republic of North Macedonia)',
    voices: ['mk-MK-AleksandarNeural', 'mk-MK-MarijaNeural'],
  },
  {
    code: 'ml-IN',
    name: 'Malayalam (India)',
    voices: ['ml-IN-MidhunNeural', 'ml-IN-SobhanaNeural'],
  },
  {
    code: 'mn-MN',
    name: 'Mongolian (Mongolia)',
    voices: ['mn-MN-BataaNeural', 'mn-MN-YesuiNeural'],
  },
  {
    code: 'mr-IN',
    name: 'Marathi (India)',
    voices: ['mr-IN-AarohiNeural', 'mr-IN-ManoharNeural'],
  },
  {
    code: 'ms-MY',
    name: 'Malay (Malaysia)',
    voices: ['ms-MY-OsmanNeural', 'ms-MY-YasminNeural'],
  },
  {
    code: 'mt-MT',
    name: 'Maltese (Malta)',
    voices: ['mt-MT-GraceNeural', 'mt-MT-JosephNeural'],
  },
  {
    code: 'my-MM',
    name: 'Burmese (Myanmar)',
    voices: ['my-MM-NilarNeural', 'my-MM-ThihaNeural'],
  },
  {
    code: 'nb-NO',
    name: 'Norwegian (Bokmål, Norway)',
    voices: ['nb-NO-FinnNeural', 'nb-NO-IselinNeural', 'nb-NO-PernilleNeural'],
  },
  {
    code: 'ne-NP',
    name: 'Nepali (Nepal)',
    voices: ['ne-NP-HemkalaNeural', 'ne-NP-SagarNeural'],
  },
  {
    code: 'nl-BE',
    name: 'Dutch (Belgium)',
    voices: ['nl-BE-ArnaudNeural', 'nl-BE-DenaNeural'],
  },
  {
    code: 'nl-NL',
    name: 'Dutch (Netherlands)',
    voices: ['nl-NL-ColetteNeural', 'nl-NL-FennaNeural', 'nl-NL-MaartenNeural'],
  },
  {
    code: 'pl-PL',
    name: 'Polish (Poland)',
    voices: ['pl-PL-AgnieszkaNeural', 'pl-PL-MarekNeural', 'pl-PL-ZofiaNeural'],
  },
  {
    code: 'ps-AF',
    name: 'Pashto (Afghanistan)',
    voices: ['ps-AF-GulNawazNeural', 'ps-AF-LatifaNeural'],
  },
  {
    code: 'pt-BR',
    name: 'Portuguese (Brazil)',
    voices: [
      'pt-BR-AntonioNeural',
      'pt-BR-BrendaNeural',
      'pt-BR-DonatoNeural',
      'pt-BR-ElzaNeural',
      'pt-BR-FabioNeural',
      'pt-BR-FranciscaNeural',
      'pt-BR-GiovannaNeural',
      'pt-BR-HumbertoNeural',
      'pt-BR-JulioNeural',
      'pt-BR-LeilaNeural',
      'pt-BR-LeticiaNeural',
      'pt-BR-ManuelaNeural',
      'pt-BR-NicolauNeural',
      'pt-BR-ValerioNeural',
      'pt-BR-YaraNeural',
    ],
  },
  {
    code: 'pt-PT',
    name: 'Portuguese (Portugal)',
    voices: ['pt-PT-DuarteNeural', 'pt-PT-FernandaNeural', 'pt-PT-RaquelNeural'],
  },
  {
    code: 'ro-RO',
    name: 'Romanian (Romania)',
    voices: ['ro-RO-AlinaNeural', 'ro-RO-EmilNeural'],
  },
  {
    code: 'ru-RU',
    name: 'Russian (Russia)',
    voices: ['ru-RU-DariyaNeural', 'ru-RU-DmitryNeural', 'ru-RU-SvetlanaNeural'],
  },
  {
    code: 'si-LK',
    name: 'Sinhala (Sri Lanka)',
    voices: ['si-LK-SameeraNeural', 'si-LK-ThiliniNeural'],
  },
  {
    code: 'sk-SK',
    name: 'Slovak (Slovakia)',
    voices: ['sk-SK-LukasNeural', 'sk-SK-ViktoriaNeural'],
  },
  {
    code: 'sl-SI',
    name: 'Slovenian (Slovenia)',
    voices: ['sl-SI-PetraNeural', 'sl-SI-RokNeural'],
  },
  {
    code: 'so-SO',
    name: 'Somali (Somalia)',
    voices: ['so-SO-MuuseNeural', 'so-SO-UbaxNeural'],
  },
  {
    code: 'sq-AL',
    name: 'Albanian (Albania)',
    voices: ['sq-AL-AnilaNeural', 'sq-AL-IlirNeural'],
  },
  {
    code: 'sr-RS',
    name: 'Serbian (Serbia)',
    voices: ['sr-RS-NicholasNeural', 'sr-RS-SophieNeural'],
  },
  {
    code: 'su-ID',
    name: 'Sundanese (Indonesia)',
    voices: ['su-ID-JajangNeural', 'su-ID-TutiNeural'],
  },
  {
    code: 'sv-SE',
    name: 'Swedish (Sweden)',
    voices: ['sv-SE-HilleviNeural', 'sv-SE-MattiasNeural', 'sv-SE-SofieNeural'],
  },
  {
    code: 'sw-KE',
    name: 'Swahili (Kenya)',
    voices: ['sw-KE-RafikiNeural', 'sw-KE-ZuriNeural'],
  },
  {
    code: 'sw-TZ',
    name: 'Swahili (Tanzania)',
    voices: ['sw-TZ-DaudiNeural', 'sw-TZ-RehemaNeural'],
  },
  {
    code: 'ta-IN',
    name: 'Tamil (India)',
    voices: ['ta-IN-PallaviNeural', 'ta-IN-ValluvarNeural'],
  },
  {
    code: 'ta-LK',
    name: 'Tamil (Sri Lanka)',
    voices: ['ta-LK-KumarNeural', 'ta-LK-SaranyaNeural'],
  },
  {
    code: 'ta-MY',
    name: 'Tamil (Malaysia)',
    voices: ['ta-MY-KaniNeural', 'ta-MY-SuryaNeural'],
  },
  {
    code: 'ta-SG',
    name: 'Tamil (Singapore)',
    voices: ['ta-SG-AnbuNeural', 'ta-SG-VenbaNeural'],
  },
  {
    code: 'te-IN',
    name: 'Telugu (India)',
    voices: ['te-IN-MohanNeural', 'te-IN-ShrutiNeural'],
  },
  {
    code: 'th-TH',
    name: 'Thai (Thailand)',
    voices: ['th-TH-AcharaNeural', 'th-TH-NiwatNeural', 'th-TH-PremwadeeNeural'],
  },
  {
    code: 'tr-TR',
    name: 'Turkish (Turkey)',
    voices: ['tr-TR-AhmetNeural', 'tr-TR-EmelNeural'],
  },
  {
    code: 'uk-UA',
    name: 'Ukrainian (Ukraine)',
    voices: ['uk-UA-OstapNeural', 'uk-UA-PolinaNeural'],
  },
  {
    code: 'ur-IN',
    name: 'Urdu (India)',
    voices: ['ur-IN-GulNeural', 'ur-IN-SalmanNeural'],
  },
  {
    code: 'ur-PK',
    name: 'Urdu (Pakistan)',
    voices: ['ur-PK-AsadNeural', 'ur-PK-UzmaNeural'],
  },
  {
    code: 'uz-UZ',
    name: 'Uzbek (Uzbekistan)',
    voices: ['uz-UZ-MadinaNeural', 'uz-UZ-SardorNeural'],
  },
  {
    code: 'vi-VN',
    name: 'Vietnamese (Vietnam)',
    voices: ['vi-VN-HoaiMyNeural', 'vi-VN-NamMinhNeural'],
  },
  {
    code: 'wuu-CN',
    name: 'Chinese (Wu, Simplified)',
    voices: ['wuu-CN-XiaotongNeural', 'wuu-CN-YunzheNeural'],
  },
  {
    code: 'yue-CN',
    name: 'Chinese (Cantonese, Simplified)',
    voices: ['yue-CN-XiaoMinNeural', 'yue-CN-YunSongNeural'],
  },
  {
    code: 'zh-CN',
    name: 'Chinese (Mandarin, Simplified)',
    voices: [
      'zh-CN-XiaochenNeural',
      'zh-CN-XiaohanNeural',
      'zh-CN-XiaomengNeural',
      'zh-CN-XiaomoNeural',
      'zh-CN-XiaoqiuNeural',
      'zh-CN-XiaoruiNeural',
      'zh-CN-XiaoshuangNeural',
      'zh-CN-XiaoxiaoNeural',
      'zh-CN-XiaoxuanNeural',
      'zh-CN-XiaoyanNeural',
      'zh-CN-XiaoyiNeural',
      'zh-CN-XiaoyouNeural',
      'zh-CN-XiaozhenNeural',
      'zh-CN-YunfengNeural',
      'zh-CN-YunhaoNeural',
      'zh-CN-YunjianNeural',
      'zh-CN-YunxiaNeural',
      'zh-CN-YunxiNeural',
      'zh-CN-YunyangNeural',
      'zh-CN-YunyeNeural',
      'zh-CN-YunzeNeural',
    ],
  },
  {
    code: 'zh-CN-henan',
    name: 'Chinese (Zhongyuan Mandarin Henan, Simplified)',
    voices: ['zh-CN-henan-YundengNeural'],
  },
  {
    code: 'zh-CN-liaoning',
    name: 'Chinese (Northeastern Mandarin, Simplified)',
    voices: ['zh-CN-liaoning-XiaobeiNeural'],
  },
  {
    code: 'zh-CN-shaanxi',
    name: 'Chinese (Zhongyuan Mandarin Shaanxi, Simplified)',
    voices: ['zh-CN-shaanxi-XiaoniNeural'],
  },
  {
    code: 'zh-CN-shandong',
    name: 'Chinese (Jilu Mandarin, Simplified)',
    voices: ['zh-CN-shandong-YunxiangNeural'],
  },
  {
    code: 'zh-CN-sichuan',
    name: 'Chinese (Southwestern Mandarin, Simplified)',
    voices: ['zh-CN-sichuan-YunxiNeural'],
  },
  {
    code: 'zh-HK',
    name: 'Chinese (Cantonese, Traditional)',
    voices: ['zh-HK-HiuGaaiNeural', 'zh-HK-HiuMaanNeural', 'zh-HK-WanLungNeural'],
  },
  {
    code: 'zh-TW',
    name: 'Chinese (Taiwanese Mandarin)',
    voices: ['zh-TW-HsiaoChenNeural', 'zh-TW-HsiaoYuNeural', 'zh-TW-YunJheNeural'],
  },
  {
    code: 'zu-ZA',
    name: 'Zulu (South Africa)',
    voices: ['zu-ZA-ThandoNeural', 'zu-ZA-ThembaNeural'],
  },
]

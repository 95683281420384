import { StoryPortraitLogic } from 'components/storyPortraits/logic'
import { isNonNullable } from 'utils/general'

export function extractAnalyticsParams(logic: StoryPortraitLogic) {
  return {
    flow: logic.selected.picture?.contentfulId != null ? 'gt_gallery' : 'own_photo',
    image_id: [logic.selected.picture?.contentfulName, logic.selected.picture?.contentfulId]
      .filter(isNonNullable)
      .join(' - '),
    content_id:
      logic.selected.tab === 'phrase'
        ? [logic.selected.phrase?.phrase, logic.selected.phrase?.id]
            .filter(isNonNullable)
            .join(' - ')
        : 'custom ' + logic.selected.tab,
    content_type: logic.selected.tab,
  } as const
}

import { getFileBlob } from 'api/goodtrust/file'
import {
  animateContentfulMemory,
  animateUserMemory,
  getUserMemory,
  postPreviewTTS,
  postUserMemory,
} from 'api/goodtrust/user/memory'
import { isPictureTooBig, MEMORY_SIZE_LIMIT } from 'components/content/memory/utils'
import { useTakePhotoModal } from 'components/content/singingPortraits/takePhotoModal/TakePhotoModal'
import { useStoryPortraitsModal } from 'components/modal/storyPortaitsModal/StoryPortraitsModal'
import { languagesByCode } from 'components/phraseEditor/options/options'
import { LanguageType, VoiceType } from 'components/phraseEditor/PhraseEditor'
import { AudioPreviewCoordinator } from 'components/storyPortraits/coordinate'
import { ExtendedStoryPortraitMemory } from 'components/storyPortraits/extendedMemory'
import { CaughtError, handleAndToastError, toastError, toastErrorString } from 'components/Toast'
import immer, { Draft } from 'immer'
import localForage from 'localforage'
import Router from 'next/router'
import { useRef } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import useSWR, { mutate } from 'swr'
import { tp_finished } from 'utils/analytics/events/storyPortraits/tp_finished'
import { tp_image_selected } from 'utils/analytics/events/storyPortraits/tp_image_selected'
import { tp_login_wall_hit } from 'utils/analytics/events/storyPortraits/tp_login_wall_hit'
import { tp_login_wall_passed } from 'utils/analytics/events/storyPortraits/tp_login_wall_passed'
import { tp_paywall_hit } from 'utils/analytics/events/storyPortraits/tp_paywall_hit'
import { tp_paywall_passed } from 'utils/analytics/events/storyPortraits/tp_paywall_passed'
import { tp_shared } from 'utils/analytics/events/storyPortraits/tp_shared'
import { tp_start_cta_clicked } from 'utils/analytics/events/storyPortraits/tp_start_cta_clicked'
import { tp_started_animation } from 'utils/analytics/events/storyPortraits/tp_started_animation'
import { Platform } from 'utils/analytics/types'
import { isUserLoggedIn } from 'utils/auth/authFetcher'
import { CaptchaHook } from 'utils/captcha'
import { bindEveryMethod } from 'utils/class'
import { expectAlwaysNonNullable, ShouldNeverHappenError } from 'utils/error'
import { encodeQuery, unwrapResponse, unwrapResponseBlob } from 'utils/fetcher'
import { describeFileRejections } from 'utils/file'
import { isNonNullable, sleep } from 'utils/general'
import { getSessionValue, setSessionValue } from 'utils/sessionStorage'
import { ApiType } from 'utils/types'
import { TFunction } from 'react-i18next'

export abstract class StoryPortraitState {
  selected!: {
    tab: StoryPortraitAudioType
    picture?: {
      /** A url leading to the image, it might be a regular url or an object url. What's important is that it can be displayed by <img> tags and such. */
      url: string

      /** If provided, the image has been selected from one of the predefined Contentful images. */
      contentfulId?: string

      /** If provided, the image has been selected from one of the existing memories of the user. */
      memoryId?: string

      /** The name of this picture in Contentful. */
      contentfulName?: string

      /** If provided, the image's file was stored at this key in localForage. */
      localForageKey?: string
    }
    phrase?: {
      id: string
      phrase: string
    }
    recording?: {
      /** An object url leading to the wav file. */
      wavUrl: string

      /** The recording is stored at this key in localForage. */
      localForageKey: string
    }
    text: {
      voice: VoiceType
      language: LanguageType
      text: string
    }
  }
  animation?: {
    state:
      | 'will-require-login'
      | 'login-required'
      | 'will-request-animation'
      | 'will-require-upgrade'
      | 'upgrade-required'
    memoryId?: string
  }
}

export type StoryPortraitAudioType = 'text' | 'voice' | 'phrase'

export class StoryPortraitLogic extends StoryPortraitState {
  constructor(st: StoryPortraitState, inited = true) {
    super()
    Object.assign(this, st)
    this.inited = inited
    bindEveryMethod(this)
  }

  inited: boolean

  static imageLocalForageKey = '@goodtrust/story-portraits/image'
  static voiceLocalForageKey = '@goodtrust/story-portraits/voice'

  onPhraseSelected(phrase: string, phraseId: string) {
    this.update((st) => {
      st.selected.phrase = {
        id: phraseId,
        phrase,
      }
    })
  }

  async onGallerySectionPictureSelected(contentfulName: string, identifier: string, url: string) {
    const memoryMatch = identifier.match(/^userMemory\/(.*)$/)

    const logic = memoryMatch
      ? this.update((st) => {
          // is an existing memory, not contentful
          const memoryId = memoryMatch[1]

          st.selected.picture = {
            url,
            memoryId,
          }
        })
      : this.update((st) => {
          // is a contentful memory
          const contentfulId = identifier

          st.selected.picture = {
            contentfulId,
            contentfulName,
            url,
          }
        })

    tp_image_selected.fire({ logic })

    await Router.push('/story-portraits/create')
  }

  async onVoiceRecordingReady(recording: Blob) {
    await localForage.setItem(StoryPortraitLogic.voiceLocalForageKey, recording)
    this.update((st) => {
      st.selected.recording = {
        localForageKey: StoryPortraitLogic.voiceLocalForageKey,
        wavUrl: URL.createObjectURL(recording),
      }
    })
  }

  onTabSelected(tab: StoryPortraitAudioType) {
    this.update((st) => {
      st.selected.tab = tab
    })
  }

  onEnteredTextChange(text: string) {
    this.update((st) => {
      st.selected.text.text = text
    })
    audioCoordinator.onShouldStopPlayingIfPlaying()
  }

  async onPhraseVoiceClick(ctx: {
    t: TFunction<'memory'>
    voice: VoiceType
    isDesktop: boolean
    captcha: CaptchaHook
  }) {
    if (ctx.isDesktop) {
      this.onVoiceSelected(ctx.voice)
    } else {
      await ctx.captcha
        .verifyCaptcha()
        .then((captchaToken) =>
          this.playTTSPreview(
            {
              audio_language: this.selected.text.language.code,
              audio_text:
                this.selected.text.text || ctx.t('memory.story.phrase.default_tts_preview_text'),
              audio_voice: ctx.voice.code,
            },
            captchaToken
          )
        )
        .catch(handleAndToastError)
    }
  }

  async onPhraseLanguageClick(ctx: {
    t: TFunction<'memory'>
    language: LanguageType
    isDesktop: boolean
    captcha: CaptchaHook
  }) {
    if (ctx.isDesktop) {
      this.onLanguageSelected(ctx.language)
    } else {
      const audio_voice = ctx.language.voices[0]?.code
      if (!audio_voice) throw new ShouldNeverHappenError()

      await ctx.captcha
        .verifyCaptcha()
        .then((captchaToken) =>
          this.playTTSPreview(
            {
              audio_language: ctx.language.code,
              audio_text:
                this.selected.text.text || ctx.t('memory.story.phrase.default_tts_preview_text'),
              audio_voice,
            },
            captchaToken
          )
        )
        .catch(handleAndToastError)
    }
  }

  onLanguageSelected(language: LanguageType) {
    this.update((st) => {
      const voice = language.voices[0]
      if (voice) {
        st.selected.text.language = language
        st.selected.text.voice = voice
      }
    })
    audioCoordinator.onUserInteraction().then(() => {
      audioCoordinator.onShouldStopPlayingIfPlaying().catch(handleAndToastError)
    })
  }

  onVoiceSelected(voice: VoiceType) {
    this.update((st) => {
      st.selected.text.voice = voice
    })
    audioCoordinator.onUserInteraction().then(() => {
      audioCoordinator.onShouldStopPlayingIfPlaying().catch(handleAndToastError)
    })
  }

  async onListenClick(captcha: CaptchaHook) {
    await audioCoordinator
      .onUserInteraction()
      .then(async () => {
        const captchaToken = await captcha.verifyCaptcha()
        await this.playSelectedTTSPreview(captchaToken)
      })
      .catch(handleAndToastError)
  }

  async playSelectedTTSPreview(captchaToken: string) {
    await this.playTTSPreview(
      {
        audio_language: this.selected.text.language.code,
        audio_voice: this.selected.text.voice.code,
        audio_text: this.selected.text.text,
      },
      captchaToken
    )
  }

  async playTTSPreview(request: ApiType['TextToSpeechCommand'], captchaToken: string) {
    await postPreviewTTS(request, captchaToken)
      .then(unwrapResponse.body)
      .then(this.onPreviewTTSReceived)
      .catch(handleAndToastError)
  }

  async onPreviewTTSReceived(res: ApiType['TextToSpeechResponse']) {
    const fileUrl = res.file?.url
    if (!fileUrl) {
      // the file url should be defined
      throw new ShouldNeverHappenError()
    }

    await audioCoordinator.onAudioReceived(fileUrl).catch(handleAndToastError)
  }

  onVoiceRecordingDiscarded() {
    this.update((st) => {
      st.selected.recording = undefined
    })
  }

  async onAnimatingPageMounted() {
    if (!this.animation) {
      await Router.push('/story-portraits/create')
      return
    }
    if (this.animation.state === 'will-require-login') {
      sleep(3e3)
        .then(() =>
          this.update((st) => {
            if (st.animation) {
              st.animation.state = 'login-required'
            }
          })
        )
        .catch(handleAndToastError)
    }
  }

  async onCreatePageMounted() {
    if (!this.selected.picture) {
      await Router.push('/story-portraits')
    }
    this.update((st) => {
      st.animation = undefined
    })
  }

  useChooseImageButton() {
    const [modal, showModal] = useStoryPortraitsModal()
    return {
      modal,
      onClick: () => {
        tp_start_cta_clicked.fire({ flow: 'gt_gallery' })
        showModal()
      },
    }
  }

  useSelfieButton() {
    const [modal, open, close] = useTakePhotoModal((photo) => {
      close()
      StoryPortraitLogic.latest.onSelfieTaken(photo)
    })

    return {
      modal,
      onClick: () => {
        tp_start_cta_clicked.fire({ flow: 'own_photo' })
        open()
      },
    }
  }

  onSelfieTaken(selfie: File) {
    return this.onFileUploaded(selfie).catch(handleAndToastError)
  }

  useUploadButton(getInput: () => HTMLInputElement | undefined) {
    const dropzone = useDropzone({
      maxSize: MEMORY_SIZE_LIMIT,
      accept: 'image/png,image/jpeg',
      multiple: false,
      noClick: true,
      onDrop: this.onDrop.bind(this),
    })

    const inputRef = useRef<HTMLInputElement>(null)

    return {
      inputRef,
      dropzone,
      onClick: () => {
        tp_start_cta_clicked.fire({ flow: 'own_photo' })
        const input = getInput()
        input?.removeAttribute('capture')
        input?.click()
      },
    }
  }

  onDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    if (fileRejections.length > 0) {
      toastErrorString(describeFileRejections(fileRejections))
    } else if (acceptedFiles.length) {
      const file = acceptedFiles[0]
      if (file) {
        isPictureTooBig(file).then((isTooBig) => {
          if (isTooBig) {
            toastError('image_10mp_limit')
          } else {
            this.onFileUploaded(file).catch(handleAndToastError)
          }
        })
      }
    }
  }

  async onFileUploaded(file: File) {
    await localForage.setItem(StoryPortraitLogic.imageLocalForageKey, file)

    const logic = this.update((st) => {
      st.selected.picture = {
        url: URL.createObjectURL(file),
        localForageKey: StoryPortraitLogic.imageLocalForageKey,
      }
    })

    tp_image_selected.fire({ logic })

    await Router.push('/story-portraits/create')
  }

  async onLoggedIn() {
    tp_login_wall_passed.fire({ logic: this })
    const wasWaitingForLogin = this.animation?.state === 'login-required'
    if (wasWaitingForLogin) {
      await this.animate().catch(this.onAnimateError)
    }
  }

  async onStartAnimatingClick() {
    const isLoggedIn = await isUserLoggedIn()

    tp_started_animation.fire({ logic: this })

    audioCoordinator.onShouldStopPlayingIfPlaying()

    if (isLoggedIn) {
      await this.animate().catch(this.onAnimateError)
    } else {
      await this.requestLoginBeforeAnimation()
    }
  }

  async requestLoginBeforeAnimation() {
    tp_login_wall_hit.fire({
      logic: this,
    })

    this.update((st) => {
      st.animation = {
        state: 'will-require-login',
      }
    })

    await Router.push('/story-portraits/animating')
  }

  async getLocalForageRecording() {
    if (!this.selected.recording) return undefined
    return await localForage.getItem<Blob>(this.selected.recording.localForageKey)
  }

  async prepareAnimationRequest(audioType: StoryPortraitAudioType) {
    const animationRequest: ApiType['MemoryAnimationRequest'] | undefined =
      audioType === 'phrase' && this.selected.phrase?.id
        ? {
            audio_contentful_id: this.selected.phrase.id,
          }
        : audioType === 'voice'
        ? {}
        : audioType === 'text'
        ? {
            audio_language: this.selected.text.language.code,
            audio_text: this.selected.text.text,
            audio_voice: this.selected.text.voice.code,
          }
        : undefined

    if (animationRequest == null) throw new ShouldNeverHappenError()

    animationRequest.driver_type = 'LIVELY'

    const voiceBlob =
      audioType === 'voice' && this.selected.recording
        ? (await this.getLocalForageRecording()) ?? undefined
        : undefined

    const contentfulId = this.selected.picture?.contentfulId

    const image = contentfulId
      ? ({ source: 'contentful', contentfulId } as const)
      : ({
          source: 'memory',
          imageFile: this.selected.picture?.localForageKey
            ? await localForage.getItem<File>(this.selected.picture.localForageKey)
            : await getFileBlob(encodeQuery(this.selected.picture?.url ?? '', { w: 600 }))
                .then(unwrapResponseBlob)
                .then((blob) => new File([blob.blob].filter(isNonNullable), 'image.jpg')),
        } as const)

    return { animationRequest, voiceBlob, image }
  }

  async onAnimateError(err: CaughtError) {
    handleAndToastError(err)
    await Router.push('/story-portraits/create')
  }

  async animate(opts?: { reuseMemoryId?: string }) {
    const { animationRequest, voiceBlob, image } = await this.prepareAnimationRequest(
      this.selected.tab
    )

    this.update((st) => {
      st.animation = {
        state: 'will-request-animation',
      }
    })

    let memory: ApiType['MemoryResponse']

    const reuseMemoryId = opts?.reuseMemoryId ?? this.selected.picture?.memoryId

    if (reuseMemoryId && reuseMemoryId !== 'contentful') {
      memory = await getUserMemory(reuseMemoryId).then(unwrapResponse.body)
      const response = await animateUserMemory(reuseMemoryId, animationRequest, voiceBlob)
      if (response.errorCode === 'user_exceeded_free_quota') {
        this.onUpgradeRequired({ memoryUuid: reuseMemoryId })
        return
      }
      memory = unwrapResponse.body(response)
    } else if (image.source === 'contentful') {
      // fails with a 500 right now
      const response = await animateContentfulMemory(
        image.contentfulId,
        animationRequest,
        voiceBlob
      )
      if (response.errorCode === 'user_exceeded_free_quota') {
        this.onUpgradeRequired({ memoryUuid: 'contentful' })
        return
      }
      memory = unwrapResponse.body(response)
    } else {
      // fails with a 400 right now - {"message":["An error occured while communicating with D-ID"],"code":"memory_did_api_fail"}
      if (!image.imageFile) throw new ShouldNeverHappenError()
      memory = await postUserMemory(image.imageFile).then(unwrapResponse.body)

      const memoryUuid = expectAlwaysNonNullable(memory.uuid)

      const response = await animateUserMemory(memoryUuid, animationRequest, voiceBlob)

      if (response.errorCode === 'user_exceeded_free_quota') {
        this.onUpgradeRequired({ memoryUuid })
        return
      }

      memory = unwrapResponse.body(response)
    }

    if (!memory.uuid) throw new ShouldNeverHappenError()

    this.update((st) => {
      if (st.animation) {
        st.animation.memoryId = memory.uuid
      }
    })

    const extendedMemory = this.extendMemory(memory, undefined).pickLatestAnimatedFile()

    await Router.push(extendedMemory.urls().result)
  }

  async onBuyCreditsClick() {
    await Router.push(encodeQuery('/subscription/animations', { next: Router.asPath }))
  }

  async onGetPlusPlanClick() {
    await Router.push(encodeQuery('/subscription/animations', { next: Router.asPath }))
  }

  async onUpgradeRequired(ctx: {
    memoryUuid: string
    removeWatermark?: { animationUuid: string }
  }) {
    tp_paywall_hit.fire({ logic: this })
    this.update((st) => {
      st.animation = {
        state: 'will-require-upgrade',
      }
    })
    await Router.push(
      encodeQuery(`/story-portraits/${ctx.memoryUuid}`, {
        unlock: ctx.removeWatermark ? 'watermark' : 'true',
        animationUuid: ctx.removeWatermark?.animationUuid,
      })
    )
  }

  onRemovingWatermarkPageMounted(ctx: { memoryUuid: string; animationUuid: string }) {
    getUserMemory(ctx.memoryUuid)
      .then(unwrapResponse.body)
      .then((memory) => this.extendMemory(memory, ctx.animationUuid))
      .then((memory) => {
        memory.onWatermarkShouldBeRemoved()
      })
      .catch(handleAndToastError)
  }

  onUnlockPageMounted(memoryId: string) {
    // memoryId is either the memory id of the memory that should be reused
    // or it can be the string "contentful", in which case, the memory is created anew
    tp_paywall_passed.fire({
      logic: this,
    })
    this.animate({ reuseMemoryId: memoryId }).catch(this.onAnimateError)
  }

  async onPaywallClosed() {
    await Router.push('/story-portraits/create')
  }

  async onResultPageMounted(ctx: { unlock: string | undefined }) {
    if (ctx.unlock === 'true') {
      if (this.animation?.state !== 'will-require-upgrade') {
        await Router.push('/story-portraits/create')
        return
      }

      await sleep(3e3)

      this.update((st) => {
        if (st.animation) {
          st.animation.state = 'upgrade-required'
        }
      })
    }
  }

  async onResultPageReady(memory: ExtendedStoryPortraitMemory) {
    const animationState = memory.describeAnimationState()

    if (animationState.isFailed) {
      await Router.push('/story-portraits/create').then(() => {
        handleAndToastError(memory.selectAnimation()?.selectedAnimation?.error_msg)
      })
      return
    }

    if (memory.response.uuid != null && memory.response.uuid === this.animation?.memoryId) {
      this.onAnimationFinished()
    }
  }

  onAnimationFinished() {
    tp_finished.fire({ logic: this })
    this.update((st) => {
      st.selected = StoryPortraitLogic.initState().selected
    })
  }

  onShared(platform: Platform) {
    tp_shared.fire({ media_type: platform })
  }

  canStartAnimating(audioType: StoryPortraitAudioType) {
    if (!this.selected.picture) return false

    if (audioType === 'phrase') return this.selected.phrase != null
    if (audioType === 'voice') return this.selected.recording != null
    if (audioType === 'text') return this.selected.text.text.length >= 1

    return false
  }

  doesAnimationRequireUpgrade(audioType: StoryPortraitAudioType) {
    return audioType !== 'phrase'
  }

  extendMemory(memory: ApiType['MemoryResponse'], animatedFileUuid: string | undefined) {
    return new ExtendedStoryPortraitMemory(memory, animatedFileUuid)
  }

  //
  //
  //

  private update(update: (st: Draft<StoryPortraitState>) => void) {
    const updated = immer(StoryPortraitLogic.fetchState(), update)

    setSessionValue('@goodtrust/story-portrait', JSON.stringify(updated))

    mutate('@goodtrust/story-portrait')

    return new StoryPortraitLogic(updated, true)
  }

  static initState(): StoryPortraitState {
    const language = languagesByCode['en-US']
    const voice = language?.voices[0]
    if (!language || !voice) {
      // en-US should always be available and it should have a voice
      throw new ShouldNeverHappenError()
    }

    return {
      selected: {
        tab: 'phrase',
        text: {
          language,
          voice,
          text: '',
        },
      },
    }
  }

  static fetchState(): StoryPortraitState {
    try {
      const json = getSessionValue('@goodtrust/story-portrait')
      if (!json) return StoryPortraitLogic.initState()
      return JSON.parse(json)
    } catch (err) {
      return StoryPortraitLogic.initState()
    }
  }

  static latest: StoryPortraitLogic

  static fetchLogic() {
    const state = StoryPortraitLogic.fetchState()

    const isFirstLoad = !StoryPortraitLogic.latest
    if (isFirstLoad) {
      // reset
      state.animation = undefined

      setSessionValue('@goodtrust/story-portrait', JSON.stringify(state))
    }

    StoryPortraitLogic.latest = new StoryPortraitLogic(state)

    if (isFirstLoad && StoryPortraitLogic.latest.selected.picture?.localForageKey != null) {
      localForage
        .getItem<File>(StoryPortraitLogic.latest.selected.picture.localForageKey)
        .then((file) => {
          if (file && file instanceof File) {
            StoryPortraitLogic.latest.update((st) => {
              if (st.selected.picture?.localForageKey) {
                st.selected.picture.url = URL.createObjectURL(file)
              }
            })
          }
        })
    }

    if (isFirstLoad && StoryPortraitLogic.latest.selected.recording) {
      localForage
        .getItem<Blob>(StoryPortraitLogic.latest.selected.recording.localForageKey)
        .then((blob) => {
          if (blob && blob instanceof Blob) {
            StoryPortraitLogic.latest.update((st) => {
              if (st.selected.recording) {
                st.selected.recording.wavUrl = URL.createObjectURL(blob)
              }
            })
          }
        })
    }

    return StoryPortraitLogic.latest
  }
}

const audioCoordinator = new AudioPreviewCoordinator()

export function useStoryPortraitLogic() {
  const logic =
    useSWR('@goodtrust/story-portrait', StoryPortraitLogic.fetchLogic).data ??
    new StoryPortraitLogic(StoryPortraitLogic.initState(), false)

  return logic
}

import { css } from '@emotion/react'
import { Button } from 'components/button/Button'
import {
  SButtonsWrapper,
  SCheckmarkWrapper,
  SImage,
  SSelfieMaskContainer,
  STakePhotoButton,
  STextWrapper,
  SWebcamWrapper,
} from './TakePhotoModal.styled'
import { Icon } from 'components/icon'
import { useModal } from 'components/modal/Modal'
import { BlockSpinner } from 'components/spinner/Spinner'
import { Text } from 'components/Typography'
import SelfieMask from 'images/extra-svg/SelfieMask.svg'
import { useEffect, useRef } from 'react'
import Webcam from 'react-webcam'
import { colors } from 'utils/colors'
import { getFileObjectFromBase64string, useFileWithObjectUrl } from 'utils/file'
import { useDynamicTranslations } from 'utils/i18n/useDynamicTranslations'

type TakePhotoCallback = (photo: File) => void

const TakePhotoModal = (props: { close: () => void; onTakePhoto: TakePhotoCallback }) => {
  const { t, isLoading } = useDynamicTranslations('memory')
  const { file: photo, url: previewSrc, setFile: setPhoto } = useFileWithObjectUrl()
  const webcamRef = useRef<Webcam>(null)

  // this useEffect pauses all the videos on the app, and resumes them when modal is closed
  // otherwise, there is a bug on React, which causes safari to unmute muted the videos when webcam is rendered
  // more about this topic https://github.com/mozmorris/react-webcam/issues/332
  useEffect(() => {
    const videos = Array.from(document.getElementsByTagName('video'))

    videos.forEach((v) => v.pause())

    return () => {
      videos.forEach((v) => v.play())
    }
  }, [])

  const takePhoto = async () => {
    const data = webcamRef.current?.getScreenshot()
    if (data) {
      const file = await getFileObjectFromBase64string(data, 'Selfie.jpeg', 'image/jpeg')
      setPhoto(file)
    }
  }

  const retake = () => setPhoto(null)

  const save = () => {
    if (photo) {
      props.onTakePhoto(photo)
    }
  }

  if (isLoading) return <BlockSpinner />
  return (
    <div>
      <SWebcamWrapper>
        {previewSrc ? (
          <>
            <SImage src={previewSrc} />
            <SCheckmarkWrapper>
              <Icon name="Check" size="2rem" color={colors.green[200]} />
            </SCheckmarkWrapper>
          </>
        ) : (
          <>
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
              mirrored
              videoConstraints={{ aspectRatio: 1 }}
              css={{ width: '100%' }}
            />
            <SSelfieMaskContainer>
              <SelfieMask />
            </SSelfieMaskContainer>
          </>
        )}
      </SWebcamWrapper>

      <STextWrapper>
        <Text variant="bodyBold">{t('memory.singing.selfie_modal_title')}</Text>
        <br />
        <Text variant="body">{t('memory.singing.selfie_modal_text')}</Text>
      </STextWrapper>

      <SButtonsWrapper>
        {photo ? (
          <>
            <Button type="button" size="large" variant="secondary" onClick={retake}>
              {t('memory.singing.selfie_modal_retake_button')}
            </Button>
            <Button type="button" size="large" onClick={save}>
              {t('memory.singing.selfie_modal_save_button')}{' '}
            </Button>
          </>
        ) : (
          <STakePhotoButton type="button" size="large" onClick={takePhoto}>
            {t('memory.singing.selfie_modal_take_photo_button')}
          </STakePhotoButton>
        )}
      </SButtonsWrapper>
    </div>
  )
}

export const useTakePhotoModal = (onTakePhoto: TakePhotoCallback) => {
  const [modal, openModal, closeModal] = useModal(
    ({ isOpen }) =>
      isOpen ? <TakePhotoModal onTakePhoto={onTakePhoto} close={closeModal} /> : null,
    {
      modalCss: css`
        max-width: 30rem;
      `,
    }
  )
  return [modal, openModal, closeModal] as const
}

import { noop, safeWindow } from 'utils/general'

export class AudioPreviewCoordinator {
  constructor() {
    if (!safeWindow) return

    this.audio = document.createElement('audio')
    this.audio.style.display = 'none'
    document.body.appendChild(this.audio)
  }
  audio!: HTMLAudioElement

  hasPlayedInThePast = false

  async onUserInteraction() {
    if (this.hasPlayedInThePast) return
    this.hasPlayedInThePast = true
    this.audio.play().catch(noop)
  }

  async onAudioReceived(fileUrl: string) {
    const audioFinishPromise = new Promise((resolve) => {
      this.audio.addEventListener('ended', resolve, { once: true })
      this.audio.addEventListener('pause', resolve, { once: true })
    })

    this.audio.src = fileUrl
    this.audio.play().catch(noop)

    await audioFinishPromise
  }

  async onShouldStopPlayingIfPlaying() {
    if (!this.audio.paused) this.audio.pause()
  }
}

import { extractedLanguages } from 'components/phraseEditor/options/extracted'
import { LanguageType } from 'components/phraseEditor/PhraseEditor'
import { fromEntries, numberComparator } from 'utils/general'

// these languages will get sorted to top in the order
// they are specifiied in this array
const preferredLanguages = ['en-US', 'es-MX']

export const languages = extractedLanguages
  .map((lang): LanguageType => {
    return {
      ...lang,
      name_short: lang.name.replace(/\(.*\)/g, '').trim(),
      countryCode: lang.code.match(/-(.{2})/)?.[1],
      voices: lang.voices.map((code) => {
        const match = code.match(/^.*-(\w+)$/)
        const name = code.includes('AIGenerate1')
          ? 'Terry'
          : code.includes('AIGenerate2')
          ? 'Lindsey'
          : match?.[1]
              ?.replace(/Neural$/, '')
              .replace(/[A-Z]/g, (x) => ' ' + x)
              .trim() ?? code

        return {
          code,
          name,
        }
      }),
    }
  })
  // sort alphabetically
  .sort((p, n) => p.name.localeCompare(n.name))
  // then sort preferred languages to top
  .sort(
    numberComparator((x) => {
      const index = preferredLanguages.indexOf(x.code)
      return index < 0 ? Infinity : index
    })
  )

export const languagesByCode = fromEntries(languages.map((lang) => [lang.code, lang]))
